import React, {useEffect, useState} from 'react';
import moment from "moment";
import useLang from "../hooks/lang.hook";
import useAuth from "../hooks/AuthHook";
import {MDBIcon} from "mdbreact";
import {Balance} from "../components/Balance";
import {Button} from "../components/Button";
import {Line} from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from 'chart.js';
import {toast} from "../components/Toast/toast";
import {getRefStat, withdrawRef} from "../api";
import Box from "@mui/material/Box";
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Filler,
	Legend
);
const plugin = {
	id: 'legendBackground',
	beforeDraw: (chart, args, opts) => {
		var legends = chart.legend.legendItems;
		legends.forEach(function(e) {
			console.log(e)
			e.fillStyle = e.strokeStyle;
		});
	}
}
const ReferralPage2 = () => {

	const { strings } = useLang();
	const { user, setUser } = useAuth();
	const [dates, setDates] = useState([])
	const [data, setData] = useState(false);
	const [result, setResult] = useState([]);
	const [depsSumCurrentMonth, setDepsSumCurrentMonth] = useState(0);


	const refInput = React.useRef(null);


	useEffect(() => {
		getRefStat().then((data) => setData(data))
	}, [])

	useEffect(() => {
		if (data) {
			const createdAtArray = [];
			data.stat.users.forEach(user => {
				createdAtArray.push(user.createdAt);
			});

// Извлекаем createdAt из deps
			data.stat.deps.forEach(dep => {
				createdAtArray.push(dep.createdAt);
			});
			const dates = createdAtArray.map(date => moment(date));
			const allDates = [];
			const currentDate = moment(dates[0]);
			const lastDate = moment(dates[dates.length - 1]);

			while (currentDate.isSameOrBefore(lastDate, 'day')) {
				allDates.push(currentDate.format());
				currentDate.add(1, 'day');
			}

			const result = createdAtArray.map(date => {
				const countUsers = data.stat.users.filter(user => user.createdAt === date).length;
				const sumDeps = data.stat.deps.filter(dep => dep.createdAt === date).reduce((total, dep) => total + dep.sum, 0);

				return {
					date: date,
					users: countUsers,
					deps: sumDeps
				};
			});
			setDates(createdAtArray)
			setResult(result);
			const currentMonth = moment().format('YYYY-MM');
			const depsSumCurrentMonth = result.reduce((total, item) => {
				if (moment(item.date).format('YYYY-MM') === currentMonth) {
					return total + item.deps;
				}
				return total;
			}, 0);
			setDepsSumCurrentMonth(depsSumCurrentMonth);
		}
	}, [data]);
	const withdrawAction = async () => {
		withdrawRef().then(val => {
			if(val.success) {
				setUser(prev => ({...prev, referralBalance: val.data.referralBalance, balance: val.data.balance }))
			}
		})
	}
	const copyLink = (ref) => {
		refInput.current.select();
		document.execCommand("copy");
		toast({
			winner: false,
			success: true,
			title: "Успех!",
			message: "Ссылка скопирована.",
		});
	};
	return (
		<div className={'referral scrollbar scrollbar-primary overflow-auto'}>
			<div className="referral__top">
				<div className="referral__actions">
					<div className="referral__actions-item referral__actions-left">
						<label htmlFor={'link'}>Ссылка для приглашения рефералов</label>
						<div className={'referral__actions-wrap'}>
							<input ref={refInput} className={'flex-1 payment__input mainPanelColor'} type="text" value={`https://7rio.xyz/?ref=${user?._id ?? ''}`} id={'link'}/>
							<Button onClick={() => copyLink(refInput)} className={'m-0'}><MDBIcon far icon="clone" /> Скопировать</Button>
						</div>
					</div>
					<div className="referral__actions-item referral__actions-right">
						<label htmlFor={'link'}>Накоплено</label>
						<div className={'referral__actions-wrap'}>
							<Balance value={user?.referralBalance ?? 0} />
							<Button onClick={user?.referralBalance > 0 && withdrawAction} className={`m-0 ${user?.referralBalance <= 0 && "button-dis"}`}><MDBIcon fas icon="coins" /> Забрать награду</Button>
						</div>
					</div>
				</div>
			</div>
			<hr style={{ margin: '25px 0'}}/>
			<div className="referral__stats">
				<div className="referral-stat__items">
					<div className="referral-stat__item">
						<div className="referral-stat__value">{user?.referralCount ?? 0}</div>
						<div className="referral-stat__desc">Кол-во рефералов</div>
					</div>
					<div className="referral-stat__item">
						<div className="referral-stat__value"><Balance value={depsSumCurrentMonth}/></div>
						<div className="referral-stat__desc">Доход за последний месяц</div>
					</div>
					<div className="referral-stat__item">
						<div className="referral-stat__value"><Balance value={user?.referralSum ?? 0}/></div>
						<div className="referral-stat__desc">Доход за всё время</div>
					</div>
					<div className="referral-stat__item">
						<div className="referral-stat__value">{user?.referralPercent ?? 0}%</div>
						<div className="referral-stat__desc">Процент с рефералов</div>
					</div>
				</div>
				<Box className="referral__graph" sx={{ mt: 5}}>
					<Line
						style={{ position: 'relative', zIndex: 1}}
						data={{
							labels: dates?.map(el => {
								const date = new Date(el);
								const month = date.toLocaleString('default', { month: 'long', day: 'numeric' });
								return month
							}),
							datasets: [
								{
									fill: true,
									label: 'Сумма депозитов',
									data: dates?.map((el, index) => result[index]?.deps),
									borderWidth: 3,
									borderColor: '#00CD39',
									backgroundColor: 'rgba(140, 255, 172, 0.35)',
								},
								{
									fill: true,
									label: 'Кол-во рефералов',
									data: dates?.map((el, index) => result[index]?.users),
									borderWidth: 3,
									borderColor: '#005FCF',
									backgroundColor: 'rgba(111, 171, 243, 0.5)',
								},
							],
							}}
					      options={{
						      responsive: true,
						      plugins: {
							      legendBackground: {
								      color: 'transparent'
							      },
							      legend: {
								      position: 'bottom',
								      align: 'end',
								      fullSize: false,
								      reverse: true,
								      labels: {
									      boxWidth: 13,
									      pointStyle: 'rectRounded',
									      usePointStyle: true,
									      useBorderRadius: true,
									      borderRadius: 2,
								      },
							      },
							      title: {
								      display: false,
							      },
						      },
					      }}
								plugins={[plugin]}
					/>
				</Box>
			</div>
		</div>
	);
};

export default ReferralPage2;