import React, { useEffect, useState } from "react";
import Select from "react-select";
import "react-select-plus/dist/react-select-plus.css";
import { Balance } from "../Balance";
import { toast } from "../Toast/toast";
import { getPayMethods, withdraw } from "../../api";
import useLang from "../../hooks/lang.hook";
import useAuth from "../../hooks/AuthHook";
import { useSnackbar } from "notistack";

export const PayOut = (props) => {
  const { strings } = useLang();
  const { setUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar()
  const [requisites, setRequisites] = useState({
    wallet: "",
    amountRub: 0,
  });
  const [selectedOption, setSelectedOption] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [payMethods, setPayMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  const onChangeWallet = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setRequisites(prev => ({...prev, [name]: value}));
  };

  const orderWithdraw = async () => {
    const order = await withdraw({
      payMethod: selectedOption.value,
      requisites,
    });
    if (order.success) {
      setUser(prev => ({...prev, balance: order.balance}))
      enqueueSnackbar(strings.responseCodes[order.code], {
        variant: 'win',
        title: 'OK'
      })
    } else {
      enqueueSnackbar(strings.responseCodes[order.code], {
        variant: 'errorAlert',
        title: ':('
      })
    }
  };

  useEffect(() => {
    getPayMethods("Withdraw").then((res) => {
      setPayMethods(res.paymethods)
      setIsLoading(false)
    });
  }, []);

  useEffect(() => {
    console.log(requisites)
    if (requisites.wallet.length > 4 && requisites.amountRub > 500 && selectedOption) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [requisites]);

  return (
    !isLoading && (
      <div className="d-flex flex-column">
        <span className="color-text-lg fs_14 font-weight-normal mb-3">
          {strings.modalPay.withdraw.method}
        </span>
          <Select
            classNamePrefix="react-select"
            className="react-select-container mb-3"
            name="payMethod"
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            components={{
              IndicatorSeparator: false,
            }}
            options={
              payMethods.map((pay) => (
                {
                  value: pay,
                  label: (
                    <div className="d-flex align-items-center">
                      <img
                        style={{ width: "25px", marginRight: "10px" }}
                        src={pay.icon}
                        alt=""
                      />{" "}
                      <strong className="font-weight-bolder color-text-lg">
                        {pay.name}
                      </strong>
                    </div>
                  )
                }))
            }
          />
          <span className="color-text-lg fs_14 font-weight-normal mb-3">
          {strings.modalPay.withdraw.wallet}
        </span>
          <input
            id="numForm"
            onChange={onChangeWallet}
            name="wallet"
            type="text"
            placeholder={strings.modalPay.withdraw.walletPlaceholder}
            className="payment__input mainPanelColor mb-3"
          />

          <span className="color-text-lg fs_14 font-weight-normal mb-3">
          {strings.modalPay.withdraw.sum}
        </span>
          <input
            id="sumForm"
            onChange={onChangeWallet}
            name="amountRub"
            type="number"
            placeholder={strings.modalPay.withdraw.sumPlaceholder}
            className="payment__input mainPanelColor mb-5"
          />
          {selectedOption && (
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
              <span className="color-text-lg fs_14 font-weight-normal mb-3">
                {strings.modalPay.withdraw.commission}{" "}
              </span>
                <span className="color-text-lg fs_14 font-weight-normal mb-3">
                {(+selectedOption.value.commission / 100) * +requisites.amountRub}
              </span>
              </div>
              <div className="d-flex justify-content-between">
              <span className="textMain fs_14 font-weight-normal mb-">
                {strings.modalPay.withdraw.receive}{" "}
              </span>
                <Balance
                  value={
                    +requisites.amountRub -
                    (+selectedOption.value.commission / 100) * +requisites.amountRub
                  }
                />
              </div>
            </div>
          )}
          <div
            onClick={orderWithdraw}
            color="none"
            className={`${
              submitDisabled ? "disabled button-dis" : ""
            } button text-center m-0 mt-4 my-text-dark font-weight-bold mb-3`}
          >
            {strings.modalPay.withdraw.button}
          </div>
          <p
            className="color-text-md fs_12 text-center font-weight-normal"
            dangerouslySetInnerHTML={{
              __html: strings.modalPay.withdraw.description,
            }}
          ></p>
      </div>
    )
  );
};
