import {createContext} from 'react'


function select () {
}

export const LangContext = createContext({
    strings: null,
    setLang: select,
    currentLang: null
})

