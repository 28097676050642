import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch, useLocation } from "react-router-dom";
import { MDBIcon } from "mdbreact";
import { useTransition, animated } from 'react-spring';
import { NavbarPage } from "../components/Navbar";
import { Mobile } from "../components/Responsive";
import { MobileMenu } from "../components/MobileMenu";
import { Sidebar } from "../components/Sidebar";
import { Room as RoomBtn } from "../components/Room";
import { MainPage } from "./MainPage";
import { FaqPage } from "./FaqPage";
import { TopPage } from "./TopPage";
import { ReferralPage } from "./ReferralPage";
import { HistoryPage } from "./HistoryPage";
import { Payments } from "../components/Payments";
import { SupportModal } from "../components/SupportModal";
import { manager } from "../services/socket";
import useLang from "../hooks/lang.hook";
import useAuth from "../hooks/AuthHook";
import useVisual from "../hooks/VisualHook";
import { LoginPage } from "./LoginPage";
import { BonusPage } from "./BonusPage";
import BombsPage from "./BombsPage";
import PromoPage from "./PromoPage";
import ProfilePage from "./ProfilePage";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import ReferralPage2 from "./ReferralPage2";



const Container = () => {
  const socketGame = manager.socket('/game');
  const socketPay = manager.socket('/pay');
  const [online, setOnline] = React.useState(0);
  const { strings } = useLang();
  const { enqueueSnackbar } = useSnackbar()
  const { isReady, user, setUser } = useAuth();
  const { visual } = useVisual();
  const location = useLocation()
  const transitions = useTransition(location, {
    from:{ transform: 'translateX(100%)' },
    leave: { transform: 'translateX(-100%)' },
    enter: (item) => async (next, cancel) => {
      await next({ transform: 'translateX(-0%)' })
    },
  }, )
  const [showChat, setShowChat] = useState(true);
  const [rouletteRooms, setRouletteRooms] = useState([]);
  const [activeRoom, setActiveRoom] = useState('');
  const [roomData, setRoomData] = useState({});
  const [showModalPayments, setShowModalPayments] = useState(false);
  const [showModalSupport, setShowModalSupport] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [userBetData, setUserBetData] = useState({
    chance: 0,
    sum: 0
  });

  const selectRoom = (roomName) => {
    setActiveRoom(roomName);
    socketGame.emit('connectInRoom', {
      current: activeRoom,
      new: roomName
    }, (cb) => {
      localStorage.setItem('room', roomName)
      setRoomData(cb)
    })
  }

  useEffect(() => {
    socketGame.on('dep', (socket) => {
      enqueueSnackbar(strings.responseCodes.success_dep, {
        title: strings.responseCodes.winTitle,
        variant: 'win',
      })
    })
    socketGame.on('getBalance', (socket) => {
      setUser((prev) => ({...prev, balance: socket.balance}))
    })
    socketGame.on('connect', (socket) => {

      socketGame.on('getRooms', (socket) => {
        setRouletteRooms(socket.rooms)
        const room = localStorage.getItem('room') ?? socket.rooms[0].room;
        selectRoom(room)
      })
      socketGame.on('updateRoom', (socket) => {
        setRouletteRooms(prev => prev.map( el => {
          if (el.room === socket.room) {
            console.log({...el, ...socket })
            return {...el, ...socket }
          }
          return el
        }
        ))
      })
      socketGame.on('checkOnlineUsers', (socket) => {
        setOnline(socket.users)
      })
      socketGame.on('winner', (socket) => {
        enqueueSnackbar(strings.responseCodes.win, {
          title: strings.responseCodes.winTitle,
          variant: 'win',
        })
        setUser(prev => ({...prev, balance: prev.balance + socket.bank}))
      })
      socketGame.on('getPercent', (socket) => {
        setUserBetData(socket)
      })
    })
  }, [])

  return (
    user.isAuthenticated ?
      isReady && (
        <div className="m-0 p-0 flex-column main-height">
          <NavbarPage openMobileMenuHandler={setShowMobileMenu} openModalPayments={setShowModalPayments}/>
          <Mobile>
            <MobileMenu visual={visual} show={showMobileMenu} openMobileMenuHandler={setShowMobileMenu} openModalPayments={setShowModalPayments}/>
          </Mobile>
          <Box sx={{ position: 'relative' }} className="m-0 app-container mainBg flex-1">
            <Box sx={{height: '100%' }} className="p-0 d-flex mobile-width">
              <div className="p-0 sidebar-container">
                <Sidebar online={online} visual={visual} openChat={setShowChat} openModalPayments={setShowModalPayments} openModalSupport={setShowModalSupport} />
              </div>
              {/*<Chat show={showChat} closeHandler={()=> setShowChat(prev => !prev)}/>*/}
            </Box>
            <div className="overflow-hidden main-body d-flex flex-column">
              {
                location.pathname === '/' && (
                  <div className="rooms mainColor">
                    <div className="rooms__top">
                      {
                        rouletteRooms.length > 0 && (
                          rouletteRooms.map((room) => <RoomBtn
                            name={room.room}
                            key={room.room}
                            description={room.description}
                            maxPlayers={room.max_players}
                            usersLength={room.users}
                            currentBank={room.current_bank}
                            isActive={activeRoom === room.room}
                            onClick={() => selectRoom(room.room)}
                          />)
                        )
                      }
                    </div>
                    <div className="rooms__bottom">
                      <div className="rooms__buttons">
                        <NavLink activeStyle={{color: "#00cd39"}} to={"/top"} className="color-text-lg mr-3"><MDBIcon icon="star" /> <strong>{strings.game.top}</strong> </NavLink>
                        <NavLink activeStyle={{color: "#00cd39"}} to={"/history"} className="color-text-lg"><MDBIcon icon="history" /> <strong>{strings.game.history}</strong> </NavLink>
                      </div>
                      <div className="rooms__chance">
                        <strong className="textMain">{strings.game.chance}: <span>~{userBetData?.chance?.toFixed(2)}%</span> <span> ({userBetData?.sum?.toFixed(2)})</span></strong>
                      </div>
                    </div>
                  </div>
                )
              }
              <Switch location={location}>
                <Route path={'/'} exact render={() => <MainPage room={activeRoom} game={roomData} socketGame={socketGame} />}/>
                <Route path={'/bombs'} exact render={() => <BombsPage socketGame={socketGame} />}/>
                <Route path={'/faq'}  render={()=> <FaqPage visual={visual} toggleSupport={ setShowModalSupport } />}/>
                <Route path={'/top'} exact render={()=> <TopPage />}/>
                <Route path={'/referrals'} exact render={()=> <ReferralPage2 />}/>
                <Route path={'/promo'} exact render={()=> <PromoPage />}/>
                <Route path={'/bonus'} exact render={()=> <BonusPage />}/>
                <Route path={'/history'} exact render={()=> <HistoryPage room={activeRoom} />}/>
                <Route path={'/profile'} exact render={()=> <ProfilePage />}/>
              </Switch>
              <Payments openHandler={ ()=> setShowModalPayments(prev => !prev) } show={ showModalPayments } />
              <SupportModal openHandler={ ()=> setShowModalSupport(prev => !prev) } show={ showModalSupport } />
            </div>
          </Box>
        </div>
      ) :
      <LoginPage />
  );
};

export default Container;