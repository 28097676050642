import React, { useCallback, useEffect, useState } from "react";
import { BetButton } from "../BetButton";
import kassa_logo from "../Payments/free-kassa-logo.svg";
import { SubmitPay } from "../SubmitPay";
import useLang from "../../hooks/lang.hook";
import { createHashPay, deposit, getPayMethods } from "../../api";
import useAuth from "../../hooks/AuthHook";

const Deposit = ({setModalQiwiData}) => {
  const { strings } = useLang();
  const { user } = useAuth();

  const merchantId = 51413;

  const [payMethods, setPayMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState({})
  const [depositValue, setDepositValue] = useState('')
  const [hash, setHash] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    createHashPay({
      merchantId,
      payId: user?._id,
      currency: "RUB",
      amount: depositValue,
    }).then(res => {
      if(res.success) {
        return setHash(res.hash);
      }
    })
  }, [depositValue])

  useEffect(() => {
    getPayMethods("Deposit").then(res => {
      setPayMethods(res.paymethods)
      setIsLoading(false)
    })
  }, []);

  const qiwiP2PAction = async () => {
    const query = await deposit(depositValue);
    if (query.success) {
      setModalQiwiData({
        amount: query.data.amount,
        comment: query.data.comment,
        wallet: query.data.wallet,
      })
    }
  };

  return (
    !isLoading && (
      <>
        <div className="d-flex flex-column">
        <span className="color-text-lg fs_14 font-weight-normal mb-3">
          {strings.modalPay.deposit.method}
        </span>
          <div className="input-group d-flex mb-3">
            {
              payMethods.length > 0 && (
                payMethods.map((payMethod) => (
                  <button
                    key={payMethod?.name}
                    onClick={() => setSelectedMethod(payMethod)}
                    className={`${
                      selectedMethod?.name === payMethod?.name ? "pay__selected" : ""
                    } pay__btn game__val-btn mr-3 p-3`}
                  >
                    <img className="w-100" src={payMethod?.icon} alt="" />
                  </button>
                ))
              )
            }
          </div>
          <span className="color-text-lg fs_14 font-weight-normal mb-3">
          {strings.modalPay.deposit.packet}
        </span>
          <div className="input-group mb-3">
            <BetButton className="mr-2" onClick={() => setDepositValue(100)} value={100} />
            <BetButton className="mr-2" onClick={() => setDepositValue(250)} value={250} />
            <BetButton className="mr-2" onClick={() => setDepositValue(500)} value={500} />
            <BetButton className="mr-2" onClick={() => setDepositValue(1000)} value={1000} />
          </div>
          <span className="color-text-lg fs_14 font-weight-normal mb-3">
          {strings.modalPay.deposit.sum}
        </span>
          <input
            id="payForm"
            value={depositValue}
            onChange={(e) => setDepositValue(e.target.value)}
            type="number"
            placeholder={strings.modalPay.deposit.sumPlaceholder}
            className="payment__input mb-3 mainPanelColor"
          />
          <div className="pay__kassa d-flex flex-row flex-wrap mb-3 justify-content-around align-items-center ">
            <img href="https://free-kassa.ru/" src={kassa_logo} alt="" />
            <p
              className="fs_14 color-text-md m-0"
              dangerouslySetInnerHTML={{
                __html: strings.modalPay.deposit.description,
              }}
            ></p>
          </div>
          {selectedMethod.name === "QIWI" ? (
            <form
              method="post"
              target="_blank"
              action={`${process.env.REACT_APP_URL}/api/pay/deposit/qiwi`}
            >
              <input type="hidden" name="m" value={merchantId} />
              <input type="hidden" name="amount" value={depositValue} />
              <input type="hidden" name="o" value={user._id} />
              <input type="hidden" name="us_userId" value={user._id} />
              <label
                htmlFor="payQiwi"
                color="none"
                className={
                  depositValue <= 0
                    ? "disabled button-dis button w-100 text-center m-0 mt-4 my-text-dark font-weight-bold"
                    : "button w-100 text-center m-0 mt-4 my-text-dark font-weight-bold"
                }
              >
                {strings.modalPay.deposit.button}
              </label>
              <input id="payQiwi" hidden type="submit" />
            </form>
          ) : selectedMethod.name === "fk" ? (
            <form method="get" target="_blank" action="https://pay.freekassa.ru">
              <input type="hidden" name="m" value={merchantId} />
              <input type="hidden" name="oa" value={depositValue} />
              <input type="hidden" name="o" value={user._id} />
              <input type="hidden" name="s" value={hash} />
              <input type="hidden" name="currency" value="RUB" />
              <input type="hidden" name="us_userId" value={user._id} />
              <label
                htmlFor="paySub"
                color="none"
                className={
                  depositValue < 500
                    ? "disabled button-dis button w-100 text-center m-0 mt-4 my-text-dark font-weight-bold"
                    : "button w-100 text-center m-0 mt-4 my-text-dark font-weight-bold"
                }
              >
                {strings.modalPay.deposit.button}
              </label>
              <input id="paySub" hidden type="submit" />
            </form>
          ) : selectedMethod.name === "QIWI_P2P" ? (
            <div>
              <input type="hidden" name="amount" value={depositValue} />
              <label
                htmlFor="payQiwiP2P"
                color="none"
                onClick={qiwiP2PAction}
                className={
                  depositValue <= 0
                    ? "disabled button-dis button w-100 text-center m-0 mt-4 my-text-dark font-weight-bold"
                    : "button w-100 text-center m-0 mt-4 my-text-dark font-weight-bold"
                }
              >
                {strings.modalPay.deposit.button}
              </label>
              <input id="payQiwiP2P" hidden />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  );
};

export default Deposit;
