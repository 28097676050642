import React from 'react';

export const Authvk = (props) => {
  const handleVkResponse = async () => {
    const win = window.location = `https://oauth.vk.com/authorize?client_id=${props.apiId}&redirect_uri=${props.redirectUri}&display=page`;
    let interval = setInterval(async () => {
      try {
        const params = win
          .location
          .search
          .replace('?', '')
          .split('&')
          .reduce(
            function (p, e) {
              let a = e.split('=');
              p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
              return p;
            },
            {}
          );
        if (params['code']) {
          clearInterval(interval)
          const data = await props.loginHandlerVk(params['code'])
          if (data) {
            win.close()
            window.location.reload()
          }
        }
      } catch (e) {
      }
    }, 500)
  }
  return (

    <button className={props.className} onClick={handleVkResponse}>
      {props.children}
    </button>

  )
}
