import React, { useState } from 'react'
import {MDBIcon, MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import {Loader} from "../Loader";
import {toast} from "../Toast/toast";
import { checkDeposit } from "../../api";

export const SubmitPay = ({data, show, toggle }) => {
  const [paySuccess, setPaySuccess] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const checkDepositAction = async () => {
    setDisabled(true)
    const pay = await checkDeposit(data);
    if(pay.success) {
      return setPaySuccess(true)
    }
    toast({
      success: pay.success,
      winner: false,
      title: 'Не оплачено.',
      code: pay.code,
    })
    setDisabled(false)
  }

  return(
    <MDBModal backdropClassName={'mainColor h-100 w-100'} wrapClassName={'overflow-auto'} contentClassName={"mainColor"} isOpen={show} toggle={toggle}>
      <MDBModalHeader className="border-0" closeAriaLabel={''} toggle={toggle}>
        <MDBIcon icon="dollar-sign" className="color__green" />
        <strong className="textMain ml-3 titleMain">Ожидание перевода</strong>
      </MDBModalHeader>
      <MDBModalBody className="overflow-auto">
        <div className="d-flex flex-column">
          <span className="color-text-lg fs_12 font-weight-normal mb-1">Сумма перевода:</span>
          <span className="textMain fs_14 font-weight-normal mb-3">{data.amount}RUB</span>
          <span className="color-text-lg fs_12 font-weight-normal mb-1">Комментарий к переводу:</span>
          <span className="textMain fs_14 font-weight-normal mb-3">{data.comment} </span>
          <span className="color-text-lg fs_12 font-weight-normal mb-1">QIWI-Кошелёк:</span>
          <span className="textMain fs_14 font-weight-normal mb-3">{data.wallet}</span>
          <div className="d-flex flex-column flex-center justify-content-center mt-3">
            <div style={{height: "100px"}} className="w-25 position-relative">
              <Loader success={paySuccess} loading={true}/>
            </div>
            <span className="color-text-lg text-center fs_14 font-weight-normal mt-3">
                После оплаты нажмите кнопку ниже. <br/> ВАЖНО!
                Комментарий и сумма платежа должны соответствовать указанным на сайте!
            </span>
            {!paySuccess && <div
              onClick={!disabled && checkDepositAction}
              className={`${disabled && 'disabled button-dis'} button w-100 text-center m-0 mt-4 my-text-dark font-weight-bold`}>
              Я оплатил
            </div>}
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  )
}