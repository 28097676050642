import React, { useEffect, useState } from 'react';
import { Balance } from "../Balance";
import { Button } from "../Button";
import {
  MDBAnimation, MDBBtn, MDBDropdown,
  MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle,
  MDBIcon,
  MDBModal, MDBModalBody, MDBModalHeader
} from "mdbreact";
import logo from "../Navbar/logo.svg";
import flagRus from "../Navbar/flagRus.svg";
import flagEn from "../Navbar/flagEn.svg";
import { LangContext } from "../../context/LangContext";
import useLang from "../../hooks/lang.hook";
import useAuth from "../../hooks/AuthHook";

export const MobileMenu = ({ openMobileMenuHandler, show, visual, openModalPayments }) => {
  const { strings, setLang, currentLang } = useLang()
  const { user, logout } = useAuth();

  return (
    <MDBModal wrapClassName={'overflow-auto'} size="fluid" className={"h-100 m-0 p-0"} dialogClassName={"m-0 p-0"}
              contentClassName={"mainColor h-100 m-0 p-0"} isOpen={show} toggle={() => openMobileMenuHandler(prev => !prev)}>
      <MDBModalHeader className="border-0" closeAriaLabel={''} toggle={() => openMobileMenuHandler(prev => !prev)}>
      </MDBModalHeader>
      <MDBModalBody className="p-0 mobile__container m-0">
        <div className="mobile__top">
          <div className="user_profile d-flex align-items-center">
            <div className="avatar m-2">
              <img className="avatar__img" src={user.avatar} alt=""/>
            </div>
            <div className="user__info ml-2">
              <span className="textMain">{user.username}</span>
              <MDBDropdownItem onClick={logout} className="p-0 m-0">
                <MDBIcon className="color-text-lg" icon="door-open"/>
                <strong className="color-text-lg">{strings.navbar.logout}</strong>
              </MDBDropdownItem>
            </div>
          </div>
          <div className="mobile__balance m-2 d-flex mt-5">
            <div className="color-text-md mr-2">
              <strong>{strings.navbar.balance}</strong>
              <br/>
              <Balance value={user.balance}/>
            </div>
            <Button className="m-0" onClick={() => openModalPayments(prev => !prev)}><MDBIcon icon="piggy-bank"/></Button>
          </div>
          <hr className="room__active"/>
        </div>
        <div className="mobile__center">
          <MDBDropdown className="m-3">
            <MDBDropdownToggle caret color="transparent" className="shadow-none p-0 m-0 d-flex align-items-center">
              <img src={currentLang === 'ru' ? flagRus : flagEn} alt="" className="mr-2"/>
              <strong className="textMain fs_16">{currentLang === 'ru' ? "RUS" : "ENG"}</strong>
            </MDBDropdownToggle>
            <MDBDropdownMenu right color={"white"} basic className="p-0 textMain mainPanelColor w-none">
              <MDBDropdownItem onClick={() => setLang(currentLang === 'ru' ? 'en' : 'ru')}
                               className="p-3 m-0 d-flex align-items-center">
                <img src={currentLang === 'ru' ? flagEn : flagRus} alt="" className="mr-2"/>
                <strong className="textMain fs_16">{currentLang === 'ru' ? "ENG" : "RUS"}</strong>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <div className="mobile__social d-flex">
            <MDBBtn href={visual?.tg ?? ''} tag="a" color="none" className="socialBtn fbColor">
              <MDBIcon fab icon="facebook-f"/>
            </MDBBtn>
            <MDBBtn href={visual?.vk ?? ''} tag="a" color="none" className="socialBtn vkColor">
              <MDBIcon fab icon="vk"/>
            </MDBBtn>
          </div>
        </div>
        <div className="mobile__bottom">
          <div>
            <hr className="room__active"/>
            <img className="nav__logo" src={logo} alt=""/>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>
  )
}