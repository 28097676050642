import React, { memo, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import useCurrency from "../../hooks/CurrencyHook";
import {Box} from "@mui/material";

export const Balance = memo(({ value, className, w, h }) => {
  const { convertCurrency, currency } = useCurrency()
  const [flip, setFlip] = useState(true)
  const [num, setNum] = useState(0);
  const { number } = useSpring({
    number: num,
    reset: false,
    from: { number: 0 },
    config: { mass: 1, tension: 500, friction: 120 },
  });

  useEffect(() => {
    setNum(value)
    setFlip(prev => !prev)
  }, [value]);

  return (
    <Box sx={{ display: 'grid', gap: '5px', gridAutoFlow: 'column', gridTemplateColumns: 'auto 1fr'}} className={`${className} balance align-items-center`}>
      <animated.span>
        {number.to(x => convertCurrency(x))}
      </animated.span>
      {" "}
      {currency === 'usd' ? (
        <span className="icon-icondollar"><span className="path1"></span><span className="path2"></span><span
          className="path3"></span><span className="path4"></span></span>
      ) : <span className="icon-rub"><span className="path1"></span><span className="path2"></span><span
        className="path3"></span><span className="path4"></span></span>}
    </Box>
  );
})
