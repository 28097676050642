import React from 'react';
import {Balance} from "../Balance";
import {motion} from "framer-motion"
export const Livedrop = (props) => {
    const colors = {
        borderTop: `4px solid ${props.user.color}`,
        background: props.user.color
    }
    const usernameSplit = () => {
        let name = props.user.username.split(' ', 2)
        let secondName = name[1].split('', 1)
        return `${name[0]} ${secondName}.`
    }
    return(

            <motion.div className="livedrop__card" style={colors}
                        animate={{
                            y: 0,
                            width:['0%', '100%']
                        }}
                        transition={{
                            y: {
                                type: "spring",
                                stiffness: 102,
                                duration: 1,
                                from: 100
                            }
                        }}
            >
                <div className="livedrop__card-balance">
                    <Balance value={props.bank}/>
                </div>
                <div className="livedrop__card-winner">
                    <img className="avatar__img mr-1" src={props.user.avatar} alt=""/>
                    <span className="color-text-lg text-center">{usernameSplit()}</span>
                </div>
            </motion.div>

    )
}