import React, {useState} from "react";
import logo from '../../pages/logo2.svg'
import flagEn from './flagEn.svg'
import flagRus from './flagRus.svg'
import { NavbarToggler } from "./NavbarToggler"
import { Balance } from "../Balance"
import { Button } from "../Button"
import { Desktop, Tablet, Mobile } from '../Responsive'
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBAnimation,
} from "mdbreact";
import useLang from "../../hooks/lang.hook";
import useAuth from "../../hooks/AuthHook";
import useCurrency from "../../hooks/CurrencyHook";
import {Helmet} from 'react-helmet';
import {NavLink} from "react-router-dom";

const NavbarPage = ({ openMobileMenuHandler, openModalPayments }) => {
  const { strings, setLang, currentLang } = useLang();
  const [theme, setTheme] = useState(false)
  const { currency, changeCurrency } = useCurrency();
  const { user, logout } = useAuth();

  return (
    <nav className="nav mainColor d-flex align-items-center justify-content-between">
      <Mobile>
        <NavbarToggler onClick={() => openMobileMenuHandler(prev => !prev)} />
      </Mobile>
      <div>
        <NavLink to="/"><img className="nav__logo" src={logo} alt=""/></NavLink>
      </div>
      <div className="nav__right d-flex flex-center">
        <Desktop>
          <MDBDropdown>
            <MDBDropdownToggle caret color="transparent" className="shadow-none p-0 m-0 d-flex align-items-center">
              <img src={currentLang === 'ru' ? flagRus : flagEn} alt="" className="mr-2"/>
              <strong className="textMain fs_16">
                {currentLang === 'ru' ? "RUS" : "ENG"}
              </strong>
            </MDBDropdownToggle>
            <MDBDropdownMenu right color={"white"} basic className="p-0 textMain mainPanelColor w-none">
              <MDBDropdownItem onClick={() => setLang(currentLang === 'ru' ? 'en' : 'ru')} className="p-3 m-0 d-flex align-items-center">
                <img src={currentLang === 'ru' ? flagEn : flagRus} alt="" className="mr-2"/>
                <strong className="textMain fs_16">
                  {currentLang === 'ru' ? "ENG" : "RUS"}
                </strong>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <MDBDropdown>
            <MDBDropdownToggle caret color="transparent" className="shadow-none p-0 ml-2 m-0 d-flex align-items-center">
              <MDBIcon icon={currency === 'rub' ? 'ruble-sign' : 'dollar-sign'} className="mr-2 sidebar__icon color-text-md " />
              <strong className="textMain fs_16">
                {currency === 'rub' ? 'RUB' : 'USD'}
              </strong>
            </MDBDropdownToggle>
            <MDBDropdownMenu right color={"white"} basic className="p-0 textMain mainPanelColor w-none">
              <MDBDropdownItem onClick={() => changeCurrency(currency === 'rub' ? 'usd' : 'rub')} className="p-3 ml-2 m-0 d-flex align-items-center">
                <MDBIcon icon={currency === 'rub' ? 'dollar-sign' : 'ruble-sign'} className="mr-2 sidebar__icon color-text-md" />
                <strong className="textMain fs_16">
                  {currency === 'rub' ? 'USD' : 'RUB'}
                </strong>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <div style={{ marginLeft: "30px", marginRight: "20px" }} className="bords-l"></div>
          <div className="color-text-md mr-5">
            <strong>
              {strings.navbar.balance}
            </strong>
            <br/>
            <Balance value={user.balance}/>
          </div>
          <Button className="p-2 pb-2 pr-4 pl-4" onClick={() => openModalPayments(prev => !prev)}>
            {strings.navbar.deposit}
          </Button>
          <div style={{ marginLeft: "20px", marginRight: "30px" }} className="bords-r"></div>
        </Desktop>
        <Tablet>
          <MDBDropdown>
            <MDBDropdownToggle caret color="transparent" className="shadow-none p-0 m-0 d-flex align-items-center">
              <img src={currentLang === 'ru' ? flagRus : flagEn} alt="" className="mr-2"/>
              <strong className="textMain fs_16">{currentLang === 'ru' ? "RUS" : "ENG"}</strong>
            </MDBDropdownToggle>
            <MDBDropdownMenu right color={"white"} basic className="p-0 textMain mainPanelColor w-none">
              <MDBDropdownItem onClick={() => setLang(currentLang === 'ru' ? 'en' : 'ru')} className="p-3 m-0 d-flex align-items-center">
                <img src={currentLang === 'ru' ? flagEn : flagRus} alt="" className="mr-2"/>
                <strong className="textMain fs_16">
                  {currentLang === 'ru' ? "ENG" : "RUS"}
                </strong>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <div style={{ marginLeft: "30px", marginRight: "20px" }} className="bords-l"></div>
          <div className="color-text-md mr-5">
            <strong>
              {strings.navbar.balance}
            </strong>
            <br/>
            <Balance value={user.balance}/>
          </div>
          <Button onClick={() => openModalPayments(prev => !prev)}><MDBIcon icon="piggy-bank"/></Button>
          <div style={{ marginLeft: "20px", marginRight: "30px" }} className="bords-r"></div>
        </Tablet>
        <div className="user_profile mr-3">
          <div className="avatar">
            <img className="avatar__img" src={user.avatar} alt=""/>
          </div>
          <Desktop>
            <MDBDropdown>
              <MDBDropdownToggle caret color="transparent" className="shadow-none p-0 m-0">
                <span>
                  <strong className="textMain chat__message-username">
                    {user.username}
                  </strong>
                </span>
              </MDBDropdownToggle>
              <MDBDropdownMenu right basic className="p-0 room__active w-none">
                <MDBDropdownItem onClick={logout} className="p-2 m-0">
                  <MDBIcon className="color-text-lg" icon="door-open"/>
                  <strong className="color-text-lg">
                    {strings.navbar.logout}
                  </strong>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </Desktop>
          <Tablet>
            <MDBDropdown>
              <MDBDropdownToggle caret color="transparent" className="shadow-none p-0 m-0">
                <span>
                  <strong className="textMain chat__message-username">
                    {user.username}
                  </strong>
                </span>
              </MDBDropdownToggle>
              <MDBDropdownMenu right basic className="p-0 room__active w-none">
                <MDBDropdownItem onClick={logout} className="p-2 m-0">
                  <MDBIcon className="color-text-lg" icon="door-open"/>
                  <strong className="color-text-lg">{strings.navbar.logout}</strong>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </Tablet>
        </div>
      </div>
    </nav>
  )
}

export default NavbarPage;