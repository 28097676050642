import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import icon from "../../assets/img/ez.svg";
import { MDBIcon } from "mdbreact";

const Square = styled(Box)(({ theme, bomb, clear}) => ({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  boxSizing: 'border-box',
  borderRadius: '5px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  transition: 'all 0.3s',
  fontSize: '30px',
  paddingTop: '100%',
  '&:hover': {
    opacity: '0.8',
    transition: 'all 0.5s',
    transform: 'scale(1.1)',
  },
  ...(
    !clear &&{
      backgroundImage: `url(${icon})`,
    }),
  ...(
    bomb && {
      background: '#f60542 !important',
      backgroundImage: 'none',
      paddingTop: '0%',

    '&:hover': {
      backgroundColor: '#f60542 !important',
    }
    }),
  ...(
    clear &&{
      background: 'linear-gradient(#00CD39,#00CD39) padding-box, linear-gradient(to bottom, #2CFF66, #007C23) border-box !important',
      paddingTop: '0%',
      '&:hover': {
      backgroundColor: 'linear-gradient(#00CD39,#00CD39) padding-box, linear-gradient(to bottom, #2CFF66, #007C23) border-box !important',
    }
    }),
}))

const SquareItem = ({ index, handleCheck, bomb, clear }) => {
  const checkSquare = async (id) => {
    handleCheck(id)
  }
  return (
    <Square className={'bombColor'} clear={clear} bomb={bomb} onClick={() => checkSquare(index)}>
      {
        bomb && (
          <MDBIcon icon="bomb"/>
        )
      }
      {
        clear && (
          <MDBIcon fas icon="dollar-sign" />
        )
      }
    </Square>
  );
};

export default SquareItem;