import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ProvideLang } from "./hooks/lang.hook";
import { ProvideAuth } from "./hooks/AuthHook";
import Container from "./pages/Container";
import { ProvideVisual } from "./hooks/VisualHook";
import { ProvideCurrency } from "./hooks/CurrencyHook";
import { SnackbarProvider } from "notistack";
import { Error, Success, Win } from "./components/Toast/Snackbar";


function App() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      Components={{
        errorAlert: Error,
        successAlert: Success,
        win: Win,
    }}>
      <ProvideVisual>
        <ProvideLang>
          <ProvideAuth>
            <ProvideCurrency>
              <BrowserRouter>
                <Container />
              </BrowserRouter>
            </ProvideCurrency>
          </ProvideAuth>
        </ProvideLang>
      </ProvideVisual>
    </SnackbarProvider>
  );
}

export default App;
