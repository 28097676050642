import React, { useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import RouletteBetV2 from "./RouletteBetV2";

export const RouletteV2 = ({ bets, winner, maxSum, showWinner, setShowWinner, room, rollDate, gameStatus }) => {
  const refs = React.useRef([]);
  const rouletteRef = React.useRef();
  const widthRef = React.useRef();
  const [preEnd, setPreEnd] = useState(false);
  const [rouletteCenter, setRouletteCenter] = useState(0);
  const [winnerPosition, setWinnerPosition] = useState(0);
  const [progress, setProgress] = useState(0);
  const [start, setStart] = useState(false);
  const { x } = useSpring({ from: { x: 0 }, x: showWinner ? 1 : 0, config: { duration: 500 } })

  const [betsState, setBetsState] = useState([]);
  const GetProgress = () => {
    const time = rollDate;
    const endTime = rollDate + 10000;
    const pr = new Date().getTime();
    const duration = ((pr-time)/(endTime-time)) * 1000;
    const calc = 10000 - (endTime - pr)
    setProgress(calc > 2000 ? calc : 0)
  }

  useEffect(() => {
    setProgress(0)
    setStart(false)
    setPreEnd(false)
    if (gameStatus !== 2) {
      setShowWinner(false)
    }
  }, [room, gameStatus])

  useEffect(() => {
    setBetsState(bets)
  },[bets])


  useEffect(() => {
    if (winner && rollDate) {
      GetProgress()
    }
  }, [room, rollDate])

  const randWidth = (width) => {
    return Math.floor(Math.random() * width - 1) + 1;
  };

  const getWinnerColorPos = () => {
    const elem = bets.findIndex((el) => el.color === winner.color);
    const getWinner = betsState.length - bets.length * 2 + elem;

    setTimeout(() => {
      setWinnerPosition((prev) => randWidth(refs.current[getWinner].offsetWidth) + refs?.current[getWinner]?.offsetLeft);
    }, 1000)
  };


  const multiplieArray = () => {
    for (let i = 0; i < 4; i++) {
      setBetsState((prev) => prev.concat(prev));
    }
  };

  const convertToPx = (sum, percent) => {
    let prc = rouletteRef?.current?.offsetWidth;
    let widthUser = (sum / maxSum) * 100;
    let kf = prc / 100;
    const width = kf * widthUser;
    return `${widthUser}%`;
  };

  useEffect(() => {
    setRouletteCenter(widthRef?.current?.offsetWidth / 2);
  }, [betsState]);

  const rouletteStyles = {
    height: "100%",
    width: "100%",
    display: "flex",
    gridAutoFlow: "column",
    position: "relative",
  };

  useEffect(() => {
    if(winner.color) {
      setPreEnd(true)
      setTimeout(() => multiplieArray(), 500)
    }
  }, [winner])


  useEffect(() => {
    if(betsState.length > bets.length) {
      getWinnerColorPos()
      setTimeout(() => setStart(true), 1000)
    }
  }, [betsState])
  const Animate = {
    transition: start && `all 10000ms cubic-bezier(.1,0,.01,.98) -${progress}ms`,
    transform: start ? `translate3d(${rouletteCenter-winnerPosition}px, 0px, 0px)` : `translate3d(0px, 0px, 0px)`
  }
  return (
    <div style={{ overflow: "hidden" }}>
      <div ref={widthRef} className="roulette__container" >
        <div className="roulette__arrow"><span></span></div>
        {showWinner &&
          <animated.div
            className="roulette__user"
            style={{
              display: "block",
              position: "absolute",
              zIndex: "2",
              height: "100%",
              width: '100%',
              background: winner.color,
              transform: x.interpolate(x => `scaleX(${x})`)
            }}
          ></animated.div>
        }
        <div
          onTransitionEnd={() => {
          setShowWinner(true)
          setTimeout(() => {
            setStart(false)
            setPreEnd(false)
            setProgress(0)
          }, 1000)
        }} ref={rouletteRef} className=""
             style={{
               ...rouletteStyles,
               ...Animate
          }}>
          {betsState.map((bet, i) => (
            <RouletteBetV2
              ref={(el) => (refs.current[i] = el)}
              key={i}
              color={bet.color}
              width={preEnd ? `${bet.percent}%` : convertToPx(bet.sum, bet.percent)}
            ></RouletteBetV2>
          ))}
        </div>
      </div>
    </div>
  );
};
