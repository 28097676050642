import { Axios } from "../services/axios";

export const getVisual =  async () => {
  const { data } = await Axios.get(`/api/visual/getVisual`);
  return data;
}

export const getPays = async () => {
  const { data } = await Axios.get(`/api/pay/getPays`);
  return data;
}

export const getPayMethods = async (type) => {
  const { data } = await Axios.get(`/api/pay/getPaymethods`, {
    params: {
      type,
      show: true,
    },
  });

  return data;
};

export const deposit = async (amount) => {
  const { data } = await Axios.post(`/api/pay/deposit/qiwip2p`, {
    amount
  });
  return data;
}

export const checkDeposit = async (pay) => {
  const { data } = await Axios.post(`/api/pay/deposit/qiwiCheckDeposit`, { pay });
  return data;
}

export const createHashPay = async ({ merchantId, payId, currency, amount }) => {
  const { data } = await Axios.post(`/api/pay/hash`, {
    merchantId, payId, currency, amount
  });
  return data;
}

export const withdraw = async (pay) => {
  const { data } = await Axios.post(`/api/pay/withdraw`, pay );
  return data;
}

export const getBonus = async () => {
  const { data } = await Axios.get('/api/bonus/all');
  return data;
}

export const activateBonus = async (bonus) => {
  const { data } = await Axios.post('/api/bonus/activate', { bonus })
  return data;
}

export const removePay = async (pay) => {
  const { data } = await Axios.post(`/api/pay/removePay`, pay);
  return data;
}

export const getAllPromo = async () => {
  const { data } = await Axios.get(`/api/promo/getAllUserPromo`);
  return data;
}

export const activatePromo = async (promo) => {
  const { data } = await Axios.post(`/api/promo/activatePromo`, promo);
  return data;
}

export const createPromo = async (promo) => {
  const { data } = await Axios.post(`/api/promo/newUserPromo`, promo);
  return data;
}

export const sendTicket = async (order) => {
  const { data } = await Axios.post(`/api/ticket/newTicket`, order);
  return data;
}

export const getLevels = async () => {
  const { data } = await Axios.get(`/api/levels/getLevels`);
  return data;
}

export const checkReposts = async () => {
  const { data } = await Axios.get(`/api/levels/checkReposts`);
  return data;
}

export const getReward = async () => {
  const { data } = await Axios.get(`/api/levels/getReward`);
  return data;
}

export const withdrawRef = async () => {
  const { data } = await Axios.get(`/api/ref/withdraw`);
  return data;
}

export const checkVkSubscribe = async () => {
  const { data } = await Axios.get('/api/vk/checkVk')
  return data;
}

export const connectTg = async () => {
  const { data } = await Axios.get('/api/tg/confirmTg');
  return data;
}

export const checkTgSubscribe = async () => {
  const { data } = await Axios.get('/api/tg/checkSub');
  return data;
}

export const getTop = async () => {
  const { data } = await Axios.get(`/api/users/getTop`);
  return data;
}

export const getHistory = async (room, currentPage, order) => {
  const { data } = await Axios.get(`/api/game/getHistory`, {params: {room: room, paginate: currentPage, sort: order}})
  return data
}

export const getRefStat = async () => {
  const { data } = await Axios.get('/api/ref/getStats');
  return data;
}

export const checkSub = async () => {
  const { data } = await Axios.get('/api/bonus/checkSub');
  return data;
}