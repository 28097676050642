import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBIcon,
  MDBTabContent,
  MDBTabPane,
  MDBNavItem,
  MDBNav,
  MDBNavLink,
} from "mdbreact";
import { PayHistory } from "../PayHistory";
import { PayOut } from "../PayOut";
import Deposit from "../Deposit";
import useLang from "../../hooks/lang.hook";
import { SubmitPay } from "../SubmitPay";
import {useMediaQuery} from "@mui/material";

export const Payments = ({ openHandler, show }) => {
  const { strings } = useLang();
  const matches = useMediaQuery('(min-width:800px)');
  const [activeItem, setActiveItem] = useState("1");
  const [modalQiwiData, setModalQiwiData] = useState({})
  const [showModalQiwi, setShowModalQiwi] = useState(false)

  const tabToggle = (tab) => async (e) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };

  return (
    <MDBContainer>
      <MDBModal
        overflowScroll={!matches}
        backdropClassName={'mainColor h-100 w-100'}
        wrapClassName={"overflow-auto d-flex flex-center"}
        contentClassName={"mainColor"}
        isOpen={show}
        toggle={openHandler}
      >
        <MDBModalHeader
          className="border-0"
          closeAriaLabel={""}
          toggle={openHandler}
        >
          <MDBIcon icon="dollar-sign" className="color__green" />
          <strong className="textMain ml-3 titleMain">
            {strings.modalPay.title}
          </strong>
        </MDBModalHeader>
        <MDBNav className="nav-tabs d-flex h-auto pb-4 ml-3 mr-3">
          <MDBNavItem className="flex-1 text-center mainPanelColor">
            <MDBNavLink
              link
              to="#"
              active={activeItem === "1"}
              onClick={tabToggle("1")}
              role="tab"
            >
              <strong className="color-text-lg fs_14">
                {strings.modalPay.deposit.title}
              </strong>
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className="flex-1 text-center mainPanelColor">
            <MDBNavLink
              link
              to="#"
              active={activeItem === "2"}
              onClick={tabToggle("2")}
              role="tab"
            >
              <strong className="color-text-lg fs_14">
                {strings.modalPay.withdraw.title}
              </strong>
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className="flex-1 text-center mainPanelColor">
            <MDBNavLink
              link
              to="#"
              active={activeItem === "3"}
              onClick={tabToggle("3")}
              role="tab"
            >
              <strong className="color-text-lg fs_14">
                {strings.modalPay.history.title}
              </strong>
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBModalBody className="overflow-auto">
          <MDBTabContent activeItem={activeItem}>
            <MDBTabPane tabId="1" role="tabpanel">
              <Deposit setModalQiwiData={setModalQiwiData} />
            </MDBTabPane>
            <MDBTabPane tabId="2" role="tabpanel">
              <PayOut />
            </MDBTabPane>
            <MDBTabPane tabId="3" role="tabpanel">
              <PayHistory setModalQiwiData={setModalQiwiData} />
            </MDBTabPane>
            <SubmitPay
              data={modalQiwiData}
              show={showModalQiwi}
              toggle={() => setShowModalQiwi(prev => !prev)}
            />
          </MDBTabContent>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
