import React, { useState, useCallback, useEffect, createContext, useContext } from 'react';
import {Balance} from "../components/Balance";
const storageName = 'options';
const stringsArr = {
  en:{
    responseCodes: {
      success: "Success",
      error: "Fail",
      low_bet: "Sum of bet less of minimal.",
      high_bet: "Sum of bet above the maximum.",
      low_balance: "Insufficient funds.",
      error_bet: "Unknown error.",
      wait_game: "Wait for a new game to start.",
      high_sum: "Sum of bet above the maximum.",
      add_bet: "Success bet.",
      win: "You are won",
      winTitle: "Congratulations!",
      success_dep: "Your balance is replenished on",
      error_dep: "Not payed.",
      success_withdraw: "Payment request approved.",
      fail_withdraw: "Payment request was rejected.",
      withdraw: "Request for payment is created.",
      success_promo: "You will get",
      title_promo: "Promo is activated.",
      error_promo: "Promo code activation error.",
      fail_promo: "Promo code activated or not found.",
      ticket: "Ticket created",
      ticket_err: "Error ticket created",
      ticket_email: "Email is incorrect",
    },
    back: "Back to room",
    game: {
      chance: "Chance on win",
      top: "Top players",
      history: "History",
      bet: "Make bet",
      empty: "No bets.",
      showAll: "Show all",
      win: ["won", "with a chance of"]
    },
    navbar: {
      balance: "Balance",
      deposit: "Deposit",
      logout: "Logout"
    },
    chat: {
      title: "Chat",
      input: "Enter a message.",
      disabled: "Chat available from 500RUB dep."
    },
    faq: {
      rulesTitle: "Chat rules",
      randomTitle: "Random.org",
      depositTitle: "Deposit / Withdraw",
      policyTitle: "Privacy policy",
      button: "Support",
      termsTitle: "Terms of use",
      read: "Read more",
      description: <p className="fs_14 color-text-lg">If you did not find the necessary information or you have any questions, <br/>
        Take advantage of our technical support</p>,
      info: ``
    },
    referral: {

      title: "REFERRAL",
      subtitle: "SYSTEM",
      link: "Link to invite referral",
      copy: "Copy",
      users: "Number of referrals",
      balance: "Total income from referrals",
      percent: "Percentage of referrals",
      bottomTitle: "WITH YOUTUBE",
      bottomSubtitle: "GET A PERCENTAGE",
      sum: 'In bank',
      withdraw: 'Withdraw',
      description: `If you have a Youtube channel <br/>
                   - Contact us and get <br/>
                   Increased percentage of referrals.`,
    },
    modalPay: {
      title: "Balance",
      deposit: {
        title: "Deposit",
        method: "Pay method",
        packet: "Select packet",
        sum: "Or enter your sum",
        sumPlaceholder: "Minimum 100rub",
        havePromo: "You have a promo?",
        promo: "Enter your promo.",
        button: "Deposit",
        description: `Universal calculation system <br/>
                    with more than 10 payment methods`,
      },
      withdraw: {
        title: "Withdraw",
        method: "Pay method",
        sum: "Sum",
        sumPlaceholder: "Minimum 500rub",
        wallet: "Wallet",
        walletPlaceholder: "Number of wallet",
        commission: "Commission",
        receive: "You will receive",
        button: "Withdraw",
        description: `The withdrawal is carried out within 15 minutes, in rare cases the withdrawal <br/>
                    occurs within 72 hours. Please note if you are wrong <br/>
                    entered payment details - funds will not be returned.`,
      },
      history: {
        title: "History",
        method: "Method and date",
        sum: "Sum",
        status: "Status",
        codes: ['Waiting', 'Success', 'Declined']
      }
    },
    supportModal: {
      title: "Support",
      theme: "Ticket theme",
      themes: ["Deposit or withdraw", "General issues", "Technical features", "Communication with the administration"],
      titleMessage: "Title",
      text: "Message",
      email: "Your Email address",
      button: "Send"
    },
    top: {
      wins: "Number of victories",
      name: "Player Name",
      sum: "Income"
    },
    history: {
      number: "№ game",
      winner: "Winner",
      date: "Date",
      win: "Income",
      percent: "% and № ticket",
      players: "Players list",
      random: "Check game",
      show: "Show",
      hide: "Hide"
    },
    bombs: {
      getWin: 'Withdraw',
      potentialWin: 'Bank',
      bombs_bet_err: 'Bank',
      bomb_generate_err: 'Bombs count 3-22',
      bomb_end: 'You lose',
      waitGame: 'Start game'
    }
  },
  ru: {
    responseCodes: {
      success: "Успех",
      error: "Ошибка",
      low_bet: "Сумма ставки ниже минимальной.",
      high_bet: "Сумма ставки выше максимальной.",
      low_balance: "Недостаточно средств.",
      error_bet: "Неизвестная ошибка.",
      wait_game: "Дождитесь начала новой игры.",
      high_sum: "Сумма ставки превышает лимит.",
      add_bet: "Ставка принята.",
      win: "Вы выиграли",
      winTitle: "Поздравляем!",
      success_dep: "Ваш баланс пополнен",
      error_dep: "Счёт не оплачен.",
      success_withdraw: "Заявка на выплату одобрена.",
      fail_withdraw: "Заявка на выплату отклонена.",
      withdraw: "Заявка на выплату создана.",
      success_promo: "Промокод успешно активирован",
      title_promo: "Промокод активирован.",
      error_promo: "Ошибка активации промокода.",
      fail_promo: "Промокод активирован или не найден.",
      ticket: "Сообщение отправлено",
      ticket_err: "Ошибка отправки сообщения",
      ticket_email: "Некорректный E-mail",
      vk_err: 'Вы не вступили в группу VK',
      tg_err: 'Вы не вступили в группу TG',
      vk_sub_err: 'Вы не вступили в группу VK',
      tg_sub_err: 'Вы не вступили в группу TG',
      dep_err: 'Ваш депозит менее 1000р',
      promo_err: 'Условия не выполнены',
      success_add_promo: 'Промокод добавлен',
      reposts_success: 'Посты проверены',
      reposts_err: 'Попробуйте еще раз',
      bonus_error: 'Ошибка активации бонуса',
      bonus_success: 'Бонус активирован',
      bomb_generate_err: 'Мин. бомб: 3, Макс. бомб: 24'
    },
    back: "Назад в комнату",
    game: {
      chance: "Шанс выигрыша",
      top: "Топ игроков",
      history: "История",
      bet: "Сделать ставку",
      empty: "Ставок нет.",
      showAll: "Посмотреть весь список",
      win: ["выиграл", "при шансе"]
    },
    navbar: {
      balance: "Баланс",
      deposit: "Пополнить",
      logout: "Выйти"
    },
    chat: {
      title: "Чат",
      input: "Введите сообщение.",
      disabled: "Чат доступен после 1000р. деп."
    },
    faq: {
      rulesTitle: "Правила чата",
      randomTitle: "Random.org",
      depositTitle: "Пополнение / Вывод",
      policyTitle: "Политика конфиденциальности",
      button: "Техподдержка",
      termsTitle: "Пользовательское соглашение",
      read: "Ознакомиться",
      description: `Если вы не нашли нужной информации или у вас остались вопросы, 
                воспользуйтесь нашей техподдержкой`,
      info:  ``
    },
    referral: {
      title: "РЕФЕРАЛЬНАЯ",
      subtitle: "СИСТЕМА",
      link: "Ссылка для приглашения рефералов",
      copy: "Скопировать",
      users: "Количество рефералов",
      balance: "Общий доход с рефералов",
      percent: "Процент с рефералов",
      bottomTitle: "С YOUTUBE",
      bottomSubtitle: "ПОЛУЧИ ПРОЦЕНТ",
      sum: 'Накоплено',
      withdraw: 'Забрать',
      description: `Если у вас есть YouTube канал <br/>
                - обратитесь к нам и получите <br/>
                повышенный процент с рефералов.`,
    },
    modalPay: {
      title: "Баланс",
      deposit: {
        title: "Пополнить",
        method: "Метод пополнения",
        packet: "Выберите пакет",
        sum: "Или введите свою сумму",
        sumPlaceholder: "Минимум 500руб",
        havePromo: "У вас есть промокод?",
        promo: "Введите промокод",
        button: "Пополнить",
        description: `Универсальная система расчетов, <br/>
                    с более 10 способами оплаты`,
      },
      withdraw: {
        title: "Вывести",
        method: "Способ вывода",
        sum: "Сумма вывода",
        sumPlaceholder: "Минимум 500руб.",
        wallet: "Реквизиты",
        walletPlaceholder: "Номер счёта",
        commission: "Комиссия",
        have: (data) => `Вы получите ${data}`,
        button: "Вывести",
        description: `Вывод осуществляется в течении 15 минут, в редких случая вывод <br/>
                    происходит в течении 72 часов. Пожалуйста, учтите, если вы неверно <br/>
                    ввели платежные реквизиты - средства не возвращаются.`,
      },
      history: {
        title: "История",
        method: "Способ и дата",
        sum: "Сумма",
        status: "Статус",
        codes: ['Ожидание', 'Принят', 'Отклонён']
      }
    },
    supportModal: {
      title: "Техподдержка",
      theme: "Тема запроса",
      themes: ["Пополнение или вывод", "Общие вопросы", "Технические особенности", "Связь с администрацией"],
      titleMessage: "Заголовок",
      text: "Сообщение",
      email: "Ваша почта",
      button: "Отправить"
    },
    top: {
      wins: "Количество побед",
      name: "Имя игрока",
      sum: "Доход"
    },
    history: {
      number: "№ игры",
      winner: "Победитель",
      date: "Дата",
      win: "Выигрыш",
      percent: "% и № билета",
      players: "Список игроков",
      random: "Проверить игру",
      show: "Развернуть",
      hide: "Свернуть"
    },
    bombs: {
      getWin: 'Забрать',
      potentialWin: 'Выиграно',
      bomb_generate_err: 'Количество бомб 3-22',
      bomb_end: 'Вы проиграли',
      waitGame: 'Начни игру'
    }
  }
}

const langContext = createContext();

export function ProvideLang({ children }) {
  const lang = useLangProvider();
  return <langContext.Provider value={lang}>{children}</langContext.Provider>;
}
export const useLang = () => {
  return useContext(langContext);
}
const useLangProvider = () => {
  const [currentLang, setCurrentLang] = useState('ru')
  const [strings, setStrings] = useState(stringsArr[currentLang])
  const setLang = useCallback( (lang) => {
    setCurrentLang(lang)
    setStrings(stringsArr[lang])
    localStorage.setItem(storageName, JSON.stringify({
      lang: lang
    }))
  }, [])

  useEffect( () => {
    const data = JSON.parse(localStorage.getItem(storageName))
    setStrings(stringsArr[data?.lang ?? "ru"])
    setLang(data?.lang ?? "ru")
  }, [currentLang])
  return { strings, setLang, currentLang }
}

export default useLang;