import React from "react";
import {Balance} from "../Balance"
export const BetUser = (props) => {
    const colors = {
        boxShadow: `-60px 0px 68px -69px ${props.user.color} inset`
    }
    const colorsBlock = {
        background: props.user.color
    }
    const usernameSplit = () => {
        let name = props.user.username.split(' ', 2)
        let secondName = name[1].split('', 1)
        return `${name[0]} ${secondName}.`
    }
    return (

            <div className="game__bet mainPanelColor d-flex justify-content-between">
                <div className="bet__info w-100 d-flex align-items-center" style={colors}>
                    <img className="bet__avatar ml-2 mr-2" src={props.user.avatar}/>
                    <div className="bet__stat">
                        <span className="bet__username" href="#"><strong className="textMain">{usernameSplit()}</strong></span>
                        <Balance className="bet__value" value={props.user.sum}/>
                    </div>
                </div>
                <div className="bet__color" style={colorsBlock}></div>
            </div>

    )
}