import React, {useEffect, useState} from 'react';
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBIcon
} from 'mdbreact';
import Select from "react-select";
import 'react-select-plus/dist/react-select-plus.css'
import {toast} from "../Toast/toast";
import useLang from "../../hooks/lang.hook";
import { sendTicket } from "../../api";
import {enqueueSnackbar} from "notistack";
import {useMediaQuery} from "@mui/material";



export const SupportModal = ({ show, openHandler }) => {
  const { strings } = useLang();
  const matches = useMediaQuery('(min-width:800px)');
  const [orderData, setOrderData] = useState({})
  const [disabled, setDisabled] = useState(true)

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      );
  };
  const sendTicketAction = async () => {
    setDisabled(true);
    if(validateEmail(orderData.email)) {
      const ticket = await sendTicket(orderData)
      if(ticket.success) {
        enqueueSnackbar(strings.responseCodes[ticket.code], {
          title: ticket.success ? strings.responseCodes.success : strings.responseCodes.error,
          variant: ticket.success ? 'successAlert' : 'errorAlert' ,
        })
        setOrderData({})
        openHandler(false)
      } else {
        enqueueSnackbar(strings.responseCodes[ticket.code], {
          title: ticket.success ? strings.responseCodes.success : strings.responseCodes.error,
          variant: ticket.success ? 'successAlert' : 'errorAlert' ,
        })
      }
      setDisabled(false);
    } else {
      enqueueSnackbar(strings.responseCodes['ticket_email'], {
        title: strings.responseCodes.error,
        variant: 'errorAlert' ,
      })
      setDisabled(false);
    }
  }
  const onChangeOrder = (event, label)=> {

    const name = event?.target?.name ?? label
    const value = event?.target?.value ?? event.value
    setOrderData(prev => ({...prev, [name]: value}))
  }
  useEffect(() => {
    // theme title email text
    if(orderData?.theme?.length > 2 && orderData?.title?.length > 2 && validateEmail(orderData?.email) && orderData?.text?.length > 5) {
      setDisabled(false)
    } else {
      setDisabled(true);
    }
    return () => setDisabled(true);
  }, [orderData]);
  return (
    <MDBContainer>
      <MDBModal overflowScroll={!matches} backdropClassName={'mainColor h-100 w-100'} wrapClassName={"overflow-auto d-flex flex-center"} size="sm" contentClassName={"mainColor"} isOpen={show} toggle={openHandler}>
        <MDBModalHeader className="border-0" closeAriaLabel={''} toggle={openHandler}>
          <MDBIcon icon="headset" className="color__green color-text-md" />
          <strong className="textMain ml-3 titleMain">{strings.supportModal.title}</strong>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="d-flex flex-column">
            <span className="color-text-lg fs_14 font-weight-normal mb-3">{strings.supportModal.theme}</span>

            <Select classNamePrefix="react-select" className='react-select-container mb-3' defaultValue={orderData?.theme}
                    onChange={(value) => onChangeOrder(value, 'theme')}  name="theme" components={{
              IndicatorSeparator: false
            }} options={[
              {value: 'Пополнение и вывод средств', label: <strong className="color-text-lg">{strings.supportModal.themes[0]}</strong>},
              {value: 'Общие вопросы', label: <strong className="color-text-lg">{strings.supportModal.themes[1]}</strong>},
              {value: 'Технические особенности', label: <strong className="color-text-lg">{strings.supportModal.themes[2]}</strong>},
              {value: 'Связь с администрацией', label: <strong className="color-text-lg">{strings.supportModal.themes[3]}</strong>}
            ]}/>
            <span className="color-text-lg fs_14 font-weight-normal mb-3">{strings.supportModal.titleMessage}</span>
            <input onChange={onChangeOrder} id="title" name="title" type="text" placeholder={strings.supportModal.titleMessage} className="payment__input mainPanelColor mb-3"/>
            <span className="color-text-lg fs_14 font-weight-normal mb-3">{strings.supportModal.email}</span>
            <input onChange={onChangeOrder} id="mail" name="email" type="email" placeholder="example@example.com" className="payment__input mainPanelColor mb-3"/>
            <span className="color-text-lg fs_14 font-weight-normal mb-3">{strings.supportModal.text}</span>
            <textarea onChange={onChangeOrder} name="text" className="payment__input mainPanelColor mb-3" style={{height: "100px"}} id="message" cols="30" rows="30"/>
            <div onClick={!disabled && sendTicketAction} color="none" className={`${disabled && 'disabled button-dis'} button text-center m-0 mt-4 my-text-dark font-weight-bold mb-3`}>
              {strings.supportModal.button}
            </div>
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
}
