import React, { useState } from 'react';
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

export const FaqItem = (props) => {
    const [show, setShow] = useState(false);
    return(
        <div className="m-2">
            <Card className="mainPanelColor color-text-lg shadow-none">
                <Accordion.Item onClick={() => setShow(!show)} as={Card.Header} eventKey={props?.id}>
                    <strong>{props?.title}</strong>
                </Accordion.Item>
            </Card>
          {
            show && (
              <Accordion eventKey={props?.id}>
                <Card.Body className="color-text-lg" dangerouslySetInnerHTML={{__html: props?.text}}>
                </Card.Body>
              </Accordion>
            )
          }
        </div>
    )
}