import React, {useState, useEffect} from 'react'

import {MDBIcon} from "mdbreact";

export const Timer = (props) => {
    const [timer, setTimer] = useState(props.value)
    const [intervalState, setIntervalState] = useState(false)
    useEffect(() => {
        clearInterval(intervalState)
        setIntervalState(null)
        if(props.status === 1) {
            clearInterval(intervalState)
            startTimer()
        }
    },[props.status])
    const startTimer= () => {
        setIntervalState(null)
        clearInterval(intervalState)
        const interval = setInterval(() => setTimer((prevState) => prevState - 1), 1000)
        setIntervalState(interval)
        return ()=> clearInterval(interval)
    }

    useEffect(()=>{
        if(timer <= 0) {
            clearInterval(intervalState)
        }
    }, [timer])

    useEffect(()=>{
        setTimer(props.value)
    }, [props.game])

    return (
        <div className="timer">
            <MDBIcon className="color-text-md" icon="stopwatch" />
            <strong className="textMain ml-2">
                {timer} с.
            </strong>
        </div>
    )
}