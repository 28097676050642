import React, {useEffect, useState} from 'react'
import {MDBIcon} from "mdbreact";
import {Balance} from "../components/Balance";

// Import React Table
import {CDataTable,CCollapse, CPagination} from '@coreui/react'
import {NavLink} from "react-router-dom";
import moment from "moment";
import {GameBets} from "../components/GameBets/GameBets";
import useLang from "../hooks/lang.hook";
import { getHistory } from "../api";


export const HistoryPage = (props) => {
    const [history, setHistory] = useState([])
    const { strings,currentLang } = useLang();
    const [details, setDetails] = React.useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [allPages, setAllPages] = useState(1)
    const [order, setOrder] = useState(["updatedAt", -1])
    useEffect(()=> {
      setLoading(true)
      getHistory(props.room, (currentPage - 1) * 10, order).then(val => {
        setHistory(val.data.games)
        setAllPages(val.data.counts / 10)
        setLoading(false)
      })
    },[currentPage, order])

    const onSort = (e) => {
        setOrder([e.column ?? "gameId", e.asc ? 1 : -1])
    }

    const toggleDetails = (index) => {
        const position = details.indexOf(index)
        let newDetails = details.slice()
        if (position !== -1) {
            newDetails.splice(position, 1)
        } else {
            newDetails = [...details, index]
        }
        setDetails(newDetails)
    }

    return (
      <div className="top-page overflow-auto scrollbar scrollbar-primary d-flex flex-row flex-wrap mainColor mt-2">
          <NavLink className="color-text-lg backroom d-flex align-items-center m-3" to={"/"}><MDBIcon icon="long-arrow-alt-left" /> <span>{strings.back}</span></NavLink>
          <div className="w-100 scrollbar scrollbar-primary table-scr">
              <CDataTable
                className="tables"
                addTableClasses="datatable__history scrollbar scrollbar-primary"
                loading={loading}
                items={history}
                responsive
                fields={[
                    { key: 'gameId', label: strings.history.number},
                    { key: 'winnerId', label: strings.history.winner},
                    { key: 'updatedAt', label: strings.history.date},
                    { key: 'sum', label: strings.history.win},
                    { key: 'percent', label:strings.history.percent},
                    {
                        key: 'show_details',
                        label: strings.history.players,
                        sorter: false,
                        filter: false
                    },
                ]}
                noItemsViewSlot={"No history"}
                itemsPerPage={10}
                border={false}
                outlined={false}
                hover
                sorter
                onSorterValueChange={(e) => onSort(e)}
                scopedSlots = {{
                    'gameId': (game) => (<td className="textMain" style={{ borderLeft:`3px solid ${game.winnerId.color}`,boxShadow: `101px 0px 123px -153px ${game.winnerId.color} inset`}}>{game.gameId}</td>),
                    'winnerId': (user)=>(
                      <td>
                          <div className="d-flex align-items-center">
                              <img style={{width:"36px"}} className="avatar p-0 m-0 mr-2" src={user.winnerId.avatar} alt=""/>
                              <span className="textMain">{user.winnerId.username}</span>
                          </div>
                      </td>
                    ),
                    'updatedAt':
                      (game)=>(
                        <td>
                            <span className="textMain">{moment(game.updatedAt).format('HH:mm:ss Y-MM-DD')}</span>
                        </td>
                      ),
                    'sum': (bank) => (<td><Balance value={bank.sum}/></td>),
                    'percent': (data) => (<td className={'textMain'}>{data.winnerId.percent.toFixed(2)}% {data?.winnerTicket?.toFixed(0)}</td>),
                    'show_details':
                      (item, index)=>{
                          return (
                            <td className="py-2">

                                {details.includes(index) ?
                                  <a
                                    className="game__bets-show history__expand exp__active"
                                    onClick={()=>{toggleDetails(index)}}>{strings.history.hide}
                                  </a>: <a
                                    className="game__bets-show history__expand"
                                    onClick={()=>{toggleDetails(index)}}>{strings.history.show}
                                  </a>}

                            </td>
                          )
                      },
                    'details':
                      (item, index)=>{
                          return (
                            <>{ details.includes(index) &&
                              <GameBets details={details} gameId={item._id} index={index}/>
                            }</>
                          )
                      },
                }}
              />
              <CPagination
                doubleArrows ={false}
                pages={allPages}
                activePage={currentPage}
                align={"center"}
                dots={false}
                limit={3}
                className="d-flex justify-content-center"
                previousButton={<MDBIcon icon="angle-left" />}
                nextButton={<MDBIcon icon="angle-right" />}
                onActivePageChange={(i) => setCurrentPage(i)}
              >

              </CPagination>
          </div>
      </div>
    )

}