import React from 'react'
import {MDBIcon} from 'mdbreact'
import {Balance} from "../Balance";

export const Room = ({ description, name, currentBank, maxPlayers, usersLength, isActive, onClick }) => {

    return(
        <button onClick={onClick} className={isActive ? "room__active mainPanelColor room-btn d-flex align-items-center" : "mainPanelColor room-btn d-flex align-items-center"}>
            <div className="room__settings room__wrap d-flex align-items-center">
                <strong className="textMain">{name}</strong>
                <span className="color-text-lg fs_14">{description}</span>
            </div>
            <div className={ isActive ? "room__state-active-mobile room__state room__wrap d-flex" : "room__state room__wrap d-flex "}>
                <span className="room__users"><MDBIcon icon="user-plus"  className="sidebar__icon-active"/> <strong className="textMain">{ usersLength ?? 0 }/{ maxPlayers ?? 0 }</strong></span>
                <span className={ isActive ? "room__state-active room__bets" : "room__inactive" } ><Balance value={currentBank ?? 0}/></span>
            </div>
        </button>
    )
}