import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Axios } from '../services/axios';
import queryString from "query-string";


const authContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useAuthProvide();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

const useAuthProvide = () => {
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState({
    username: '',
    role: 'user',
    _id: '',
    avatar: '',
    balance: 0,
    deps: 0,
    bonusBalance: 0,
    telegramSub: false,
    isAuthenticated: false,
    referralPercent: 0,
    referralBalance: 0,
    referralSum: 0,
    telegram: '',
    referralCount: 0,
    vkSub: false,
  });
  const getSession = useCallback(async () => {
    // const query = {
    //   data: {
    //     "user": {
    //       "username": "Evgeny Semyonov",
    //       "role": "admin",
    //       "avatar": "https://vk.com/images/camera_200.png",
    //       "balance": 0,
    //       "_id": "64509bbb3383d1a1c330a04f",
    //       "bank": 0,
    //       "deps": 0,
    //       "telegramSub": false,
    //       "referralPercent": 15,
    //       "referralBalance": 0,
    //       "referralSum": 0,
    //       "telegram": null,
    //       "bonusBalance": 0,
    //       "referralCount": 0,
    //       "vkSub": false
    //     },
    //     "isAuthenticated": true
    //   }
    // }
    const query = await Axios.get(`/api/auth/getSession`);
    if (query.data.isAuthenticated) {
      return setUser({ ...query.data.user, isAuthenticated: query.data.isAuthenticated });
    }
    return false;
  }, []);
  const stat = useCallback(async () => {
    const search = queryString.parse(window.location.search);
    const from = search.from;
    const ref = search.ref;
    const query = await Axios.post('/stats', {
      from, ref
    })
  }, [])
  const login = async (userData) => {
    const query = await Axios.get(`/api/auth/vk`, {
      code: userData
    } );
    if (query.data.isAuthenticated) {
      await getSession();
    }
    return query.data;
  };

  const logout = async () => {
    const query = await Axios.get('/api/auth/logout');
    if(query.data.success) {
      setUser({
        username: '',
        role: 'user',
        _id: '',
        avatar: '',
        balance: 0,
        deps: 0,
        bonusBalance: 0,
        telegramSub: false,
        isAuthenticated: false,
        referralPercent: 0,
        referralBalance: 0,
        referralSum: 0,
        telegram: '',
        referralCount: 0,
        vkSub: false,
      })
    }
    return false;
  };

  useEffect(() => {
    stat()
    getSession().then(() => {
      setIsReady(true)
    }).catch(() => {
      setIsReady(true)
    });
  }, []);

  return {
    user, login, logout, isReady, getSession, setUser
  };

};

export default useAuth;
