import React, { useEffect, useState } from 'react';
import { animated, useSpring } from "react-spring";

const RouletteBetV2 = React.forwardRef(({ preEnd, width, color, key, className, children }, ref) => {
  const [betWidth, setBetWidth] = useState('0%');
  const animate = useSpring({
    to: {
      minWidth: width
    },
    from: {
      minWidth: betWidth
    }
  })
  useEffect(() => {
    setBetWidth(prev => width)
  }, [width]);
  return (
    <animated.div
      ref={(el) => ref(el, key)}
      key={key}
      className="roulette__user"
      style={{ ...animate, background: color, height: '100%' }}
    >
      {children}
    </animated.div>
  );
});

export default RouletteBetV2;