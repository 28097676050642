import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Axios } from '../services/axios';
const storageName = 'currency';

const currencyContext = createContext()

export function ProvideCurrency({ children }) {
  const auth = useCurrencyProvide();
  return <currencyContext.Provider value={auth}>{children}</currencyContext.Provider>;
}

export const useCurrency = () => {
  return useContext(currencyContext);
};

const useCurrencyProvide = () => {
  const [currency, setCurrency] = useState('rub');
  const formatValue = (n, x = 3) => {
    return ((parseInt(n * 100)) / 100).toFixed(2)
    // const s = String(n)
    // let [ a, b = '' ] = s.split('.')
    // return parseFloat(a + '.' + b.substring(0, x))
  }

  const changeCurrency = useCallback( (currency) => {
    setCurrency(currency)
    localStorage.setItem(storageName, JSON.stringify({
      currency: currency
    }))
  }, [])
  const convertCurrency = (sum) => {
    if(currency === 'rub') {
      return formatValue(sum)
    }
    if(currency === 'usd') {
      return formatValue(sum / 59)
    }
  }

  useEffect( () => {
    const data = JSON.parse(localStorage.getItem(storageName))
    changeCurrency(data?.currency ?? 'rub')
  }, [currency])

  return {
    currency, convertCurrency, changeCurrency
  };

};

export default useCurrency;