import React, { useState, useEffect } from "react";
import { MDBIcon } from "mdbreact";
import moment from "moment";
import { CDataTable, CTooltip } from "@coreui/react";
import { getPays, removePay } from "../../api";
import useLang from "../../hooks/lang.hook";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/AuthHook";

export const PayHistory = ({ setModalQiwiData }) => {
  const { strings } = useLang();
  const { enqueueSnackbar } = useSnackbar()
  const { setUser } = useAuth()
  const [pays, setPays] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const removePayAction = async (pay) => {
    const removedPay = await removePay(pay);
    if (removedPay.success) {
      setPays((prevState) => prevState.filter((el) => el._id !== pay._id));
      setUser(prev => ({...prev, balance: removedPay.balance}))
      enqueueSnackbar(strings.responseCodes.success, {
        variant: 'win',
        title: 'OK'
      })
    } else {
      enqueueSnackbar(strings.responseCodes.error, {
        variant: 'errorAlert',
        title: ':('
      })
    }
  };

  useEffect(() => {
    getPays().then(res => {
      if(res.success) {
        setIsLoading(false)
        return setPays(res.pays);
      }
    })
  }, []);

  return (
    !isLoading && (
      <CDataTable
        addTableClasses="datatable__history-pay"
        items={pays}
        responsive
        fields={[
          { key: "payId", label: "№" },
          { key: "createdAt", label: strings.modalPay.history.method },
          { key: "amountRub", label: strings.modalPay.history.sum },
          { key: "status", label: strings.modalPay.history.status },
        ]}
        noItemsViewSlot={"No history"}
        itemsPerPage={6}
        border={false}
        outlined={false}
        hover
        sorter
        pagination={{
          doubleArrows: false,
          align: "center",
          dots: false,
          limit: 3,
          previousButton: <MDBIcon icon="angle-left" />,
          nextButton: <MDBIcon icon="angle-right" />,
        }}
        scopedSlots={{
          payId: (pay) => (
            <td className="textMain font-weight-normal">{pay.payId}</td>
          ),
          createdAt: (pay) => (
            <td>
            <span className="textMain font-weight-normal d-flex align-items-center">
              {pay.type === "Withdraw" ? (
                <CTooltip
                  className="p-5"
                  content={<strong>{pay.wallet}</strong>}
                  placement="top-end"
                  advancedOptions={{
                    animateFill: true,
                    theme: "tomato",
                  }}
                >
                  <img
                    style={{ width: "25px" }}
                    className="mr-2"
                    src={pay.payMethod}
                    alt=""
                  />
                </CTooltip>
              ) : (
                <img
                  style={{ width: "25px" }}
                  className="mr-2"
                  src={pay.payMethod}
                  alt=""
                />
              )}
              {moment(pay.createdAt).format("Y.MM.DD")}
            </span>
            </td>
          ),
          amountRub: (pay) => (
            <td>
              {pay.type === "Deposit" ? (
                <strong className="color__green font-weight-normal">
                      {" "}
                  +{pay.amountRub.toFixed(2)}
                    </strong>
              ) : (
                <strong className="color__red font-weight-normal">
                      {" "}
                  -{pay.amountRub.toFixed(2)}
                    </strong>
              )}
            </td>
          ),
          status: (pay) => (
            <td>
              {pay.status === 1 ? (
                <td className="d-flex align-items-center">
                  <div className="chat__circle connected mr-2"></div>{" "}
                  <span className="color__green font-weight-normal">
                  {strings.modalPay.history.codes[pay.status]}
                </span>{" "}
                </td>
              ) : pay.status === 0 && pay.type === "Withdraw" ? (
                <td className="d-flex align-items-center">
                  <div className="chat__circle wait mr-2"></div>
                  <span className="color__yellow font-weight-normal">
                  {strings.modalPay.history.codes[pay.status]}{" "}
                </span>
                  <span
                    onClick={ async () => await removePayAction(pay)}
                    className="text-danger p-1"
                  >
                  <sup>
                    <MDBIcon icon="times" />
                  </sup>
                </span>
                </td>
              ) : pay.status === 0 && pay.type === "Deposit" ? (
                <td className="d-flex align-items-center">
                  <div className="chat__circle wait mr-2"></div>
                  <span className="color__yellow font-weight-normal">
                  {strings.modalPay.history.codes[pay.status]}{" "}
                </span>
                  <span
                    onClick={() => setModalQiwiData(pay)}
                    className="text-primary p-1"
                  >
                  <sup>
                    <MDBIcon icon="eye" />
                  </sup>
                </span>
                </td>
              ) : (
                <td className="d-flex align-items-center">
                  <CTooltip
                    className="p-5"
                    content={<strong>{pay.comment}</strong>}
                    placement="bottom-end"
                    advancedOptions={{
                      animateFill: true,
                      theme: "tomato",
                    }}
                  >
                    <MDBIcon
                      className="fs_16 mr-2 color__red"
                      icon="question-circle"
                    />
                  </CTooltip>
                  <span className="color__red font-weight-normal">
                  {strings.modalPay.history.codes[3]}
                </span>
                </td>
              )}
            </td>
          ),
        }}
      />
    )
  );
};
