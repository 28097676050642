import React, { useState } from 'react';
import { Box } from "@mui/material";
import useLang from "../hooks/lang.hook";

import useAuth from "../hooks/AuthHook";
import { MDBIcon } from "mdbreact";
import { Balance } from "../components/Balance";
import { checkTgSubscribe, checkVkSubscribe, connectTg } from "../api";
import { Success } from "../components/Loader/success";
import useVisual from "../hooks/VisualHook";

const ProfilePage = () => {
  const { user, setUser } = useAuth();
  const { visual } = useVisual();
  const { strings } = useLang();

  const [tgLink, setTgLink] = useState('')
  const checkTgSubAction = async () => {
    const check = await checkTgSubscribe();
    if(check.success) {
      setUser(prev => ({...prev, bonusBalance: check.data.bonusBalance, telegramSub: check.data.telegramSub}))
    }
  }
  const checkVkSubAction = async () => {
    const check = await checkVkSubscribe();
    if(check.success) {
      setUser(prev => ({...prev, bonusBalance: check.data.bonusBalance, vkSub: check.data.vkSub}))
    }
  }
  const connectTgAction = async () => {
    const connect = await connectTg();
    if(connect.success) {
      window.open(connect.link);
      setTgLink(connect.link)
    }
  }
  return (
    <div className="container-fluid overflow-auto scrollbar-primary scrollbar mt-2 mainColor h-100">
      <div className="row">
        <div className="bonus col-md-12 d-flex flex-wrap justify-content-between" style={{ padding: '50px 100px 50px 100px' }}>
          <Box width={'100%'} gap={"50px"} display={'grid'}>
            <h3 className={'white-text'}><strong>Профиль</strong></h3>
            <h5 className={'color-text-md'}><strong>Для получения бонусов и других возможностей выполните шаги ниже:</strong></h5>
            <Box display={'grid'} gridAutoFlow={'column'} gap={'20px'}>
              <Box gridTemplateRows={'1fr 1fr 1fr'} display={'grid'} justifyItems={'center'} gap={'10px'} alignItems={'center'}>
                <MDBIcon className={'white-text align-self-start'} size={'5x'} fab icon="vk" />
                <h5 className={'color-text-md text-center'}>
                  <strong className={'text-white'}>1.</strong> Вступите в группу <br/> <strong className={'balance'}>VK</strong>
                  <br/>
                  <span className={'text-center'}>и получите
                  <Balance className={'justify-content-center'} value={7.50}/></span>
                </h5>
                {
                  !user.vkSub ? (
                    <div className="p-2 pb-2 pr-4 pl-4 align-self-end font-weight-bold white-grad my-text-dark button m-1">
                      <span onClick={checkVkSubAction} className="d-flex flex-center">Проверить</span>
                    </div>
                  ) : (
                    <Box alignSelf={'end'}><Success/></Box>
                  )
                }
              </Box>
              <Box display={'grid'} justifyItems={'center'} gap={'10px'}>
                <MDBIcon className={'white-text align-self-start'} size={'5x'} fab icon="telegram" />
                <h5 className={'color-text-md text-center'}>
                  <strong className={'text-white'}>2.</strong> Привяжите аккаунт <br/> <strong className={'balance'}>Telegram</strong>
                </h5>
                {
                  !user.telegram ? (
                    <div onClick={connectTgAction} className="p-2 pb-2 pr-4 pl-4 align-self-end font-weight-bold white-grad my-text-dark button m-1">
                      <span className="d-flex flex-center">Привязать</span>
                    </div>
                  ) : (
                    <Box alignSelf={'end'}><Success/></Box>
                  )
                }
              </Box>
              <Box display={'grid'} justifyItems={'center'} gap={'10px'}>
                <MDBIcon className={'white-text align-self-start'} size={'5x'} fab icon="telegram" />
                <h5 className={'color-text-md text-center'}>
                  <strong className={'text-white'}>3.</strong>
                    Вступите в группу <br/>
                  <strong className={'balance'}>Telegram</strong>
                  <br/>  <span className={'text-center'}>и получите
                  <Balance className={'justify-content-center'} value={7.50}/></span>
                </h5>
                {
                  !user.telegramSub ? (
                    <div onClick={checkTgSubAction} className="p-2 pb-2 pr-4 pl-4 align-self-end font-weight-bold white-grad my-text-dark button m-1">
                      <span className="d-flex flex-center">Привязать</span>
                    </div>
                  ) : (
                    <Box alignSelf={'end'}><Success/></Box>
                  )
                }
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;