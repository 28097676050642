import React from 'react'

export const Success = () => {
    return(<svg id="svg1" version="1.1" xmlns="http://www.w3.org/2000/svg"  width="94" height="94" viewBox="0 0 47 47"><title>animation icon -OK-</title>
        <path class="path" fill= "none" stroke ="#00CD39" stroke-width ="1.2" stroke-dasharray= "70.2" stroke-dashoffset="70.2"
              d="M 34.6 14.6  L 21 28.2 L 15.4 22.6 L 12.6 25.4 L 21 33.8 L 37.4 17.4z">
            <animate id="p1" attributeName="stroke-dashoffset" begin="0.2s" values="70.2;0" dur="0.3s" repeatCount="1" fill="freeze" calcMode="paced" restart="whenNotActive"/>
            <animate id="f1" attributeName="fill" begin = "p1.end" values="#4CAF50; #00CD39"  dur="2s" fill="freeze" restart="always" />
            <linearGradient
                id="paint0_linear"
                x1="39"
                x2="39"
                y1="17"
                y2="61"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#03EE44"></stop>
                <stop offset="1" stopColor="#0EB300"></stop>
            </linearGradient>
        </path>
    </svg>	)
}