import React, { useState } from "react";
import { MDBIcon } from "mdbreact";
import Accordion from "react-bootstrap/Accordion";
import { FaqItem } from "../components/FaqItem";
import useLang from "../hooks/lang.hook";

export const FaqPage = ({ visual, toggleSupport }) => {
  const { currentLang, strings } = useLang();
  const [fullPrivacy, setFullPrivacy] = useState(true);

  return (
    <div className="container-fluid overflow-auto scrollbar-primary scrollbar mt-2 mainColor">
      <div className="row">
        <div className="col-lg-8 m-auto">
          <div className="faq">
            <Accordion defaultActiveKey="0" className="p-2 p-5">
              <FaqItem
                title={strings.faq.rulesTitle}
                id="1"
                text={
                  currentLang === "ru"
                    ? visual?.rulesRu ?? ''
                    : visual?.rulesEng ?? ''
                }
              />
              <FaqItem
                title={strings.faq.depositTitle}
                id="2"
                text={
                  currentLang === "ru"
                    ? visual?.cashRu ?? ''
                    : visual?.cashEng ?? ''
                }
              />
              <FaqItem
                title={strings.faq.randomTitle}
                id="3"
                text={
                  currentLang === "ru"
                    ? visual?.randomRu ?? ''
                    : visual?.randomEng ?? ''
                }
              />
              <FaqItem
                title={strings.faq.policyTitle}
                id="4"
                text={
                  currentLang === "ru"
                    ? visual?.privacyRu ?? ''
                    : visual?.privacyEng ?? ''
                }
              />
            </Accordion>
            <div className="faq__support">
              <p className="fs_14 color-text-lg">
                {strings.faq.description}
              </p>
              <button
                onClick={toggleSupport}
                className="btn font-weight-bold button"
              >
                <MDBIcon
                  icon="headset"
                  style={{ fontSize: "20px", paddingRight: "10px" }}
                />
                <strong className="text-dark">
                  {strings.faq.button}
                </strong>{" "}
              </button>
            </div>
          </div>
        </div>
        <hr className="game__hr w-100" />

        <div className="col-lg-7 m-auto pb-5">
          <div className="faq">
            <h5 className="textMain">
              <strong>{strings.faq.termsTitle}</strong>
            </h5>
            <div
              className="fs_14 color-text-lg"
              dangerouslySetInnerHTML={{ __html: strings.faq.info }}
            ></div>
            <a
              onClick={() => setFullPrivacy(!fullPrivacy)}
              className="game__bets-show history__expand mt-5"
            >
              {strings.faq.read}
            </a>
            <div
              className={'color-text-lg'}
              hidden={fullPrivacy}
              dangerouslySetInnerHTML={{
                __html:
                  currentLang === "ru"
                    ? visual?.termsRu ?? ''
                    : visual?.termsEng ?? ''
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
