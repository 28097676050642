import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import successPic from './success.png'
import errorPic from './error.png'
import winPic from './win.png'
import {MDBAnimation} from 'mdbreact'
import {Balance} from "../Balance";
import {LangContext} from "../../context/LangContext";
import {useLang} from "../../hooks/lang.hook";

export const Toast = (props) => {
    const [animate, setAnimate] = useState("bounceInUp")
    const {strings} = useLang()
    const message = props.toast.code
    const winner = props.toast.winner
    const title = props.toast.title
    const success = props.toast.success ? successPic : errorPic
    useEffect(()=>{
        setTimeout(()=>{
            removeToast()
        }, 3000)
    },[message])
    const removeToast = () => {
        setAnimate("bounceOut")
        setTimeout(()=>{
            ReactDOM.unmountComponentAtNode(props.container)
        }, 1000)
    }
    return (
        <MDBAnimation type={animate} className="toastAlert">
            <div onClick={() => removeToast()} className={props.toast.success ? "toastBody toastSuccess" : "toastBody toastError"} >
                {winner ? <img className="toastImg" src={winPic} alt=""/> : <img className="toastImg" src={success} alt=""/> }
                {winner &&
                <div className="d-flex flex-column flex-wrap">
                    <strong className="textMain">{strings.responseCodes.winTitle}</strong>
                    <p className="color-text-lg d-flex flex-row">{strings.responseCodes.win(<Balance className="ml-2 mr-2 p-0" value={props.toast.message}/>)}</p>
                </div>
                }
                {
                    !winner &&
                    <div className="d-flex flex-column flex-wrap">
                        {message === 'success_promo' ?  <strong className="textMain">{strings.responseCodes.title_promo}</strong> : <strong className="textMain">{props.toast.success ? strings.responseCodes.success : strings.responseCodes.error }</strong>}
                        {message === 'success_dep' && <p className="color-text-lg d-flex flex-row">{strings.responseCodes.success_dep} <Balance className="ml-2" value={props.toast.value}/></p>}
                        {message === 'success_promo' ?  <p className="color-text-lg d-flex flex-row">{strings.responseCodes.success_promo} <Balance className="ml-2 mr-2 p-0" value={props.toast.value}/></p> : <p className="color-text-lg d-flex flex-row">{strings.responseCodes[message]}</p>}
                    </div>
                }
            </div>
        </MDBAnimation>
    )

}