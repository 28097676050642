import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import { Balance } from "../Balance";
import { MDBIcon } from "mdbreact";

const BombHistoryList = ({ items }) => {
  const [bets, setBets] = useState([]);
  useEffect(() => {
    setBets(items)
  }, [items])
  return (
    <Box sx={{
      display: 'grid',
      gap: '20px',
    }}>
      <Box sx={{ display: 'grid', gap: '23px', justifyItems: 'center', gridTemplateColumns: '1fr auto 1fr', alignItems: 'center'}}>
        <hr style={{ height: '0px', width: '100%'}} className="room__active"/>
        <div style={{ color: 'white'}}>Последние игры</div>
        <hr style={{ height: '0px', width: '100%'}} className="room__active"/>
      </Box>
      <Box sx={{
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        justifyContent: 'space-between',
        justifyItems: 'center',
        borderRadius: '5px',
        alignItems: 'center',
        padding: '10px',
        color: '#8a8eac',
      }}>
        <strong style={{justifySelf: 'center'}}>Имя</strong>
        <strong>Ставка</strong>
        <strong>Бомб</strong>
        <strong>Выигрыш</strong>
      </Box>
      {
        bets?.length > 0 && bets.map((el) => (
          <Box
          className="mainPanelColor"
            sx={{
              display: 'grid',
              width: '100%',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              justifyContent: 'space-between',
              justifyItems: 'center',
              borderRadius: '5px',
              border: '1px solid',
              borderColor: 'rgba(198, 204, 221, 0.05)',
              alignItems: 'center',
              padding: '10px',
          }}>
            <strong style={{ justifySelf: 'center', display: 'grid', gap: '10px', gridAutoFlow: 'column', alignItems: 'center'}} className={'white-text'}>
              <img style={{width: '35px', borderRadius: '100%'}} className={'avatar__img'} src={el?.userId?.avatar} alt=""/>
              {el?.userId?.username}
            </strong>
            <Balance value={el.userBet} />
            <Box sx={{ display: 'grid', gap: '10px', gridAutoFlow: 'column', alignItems: 'center'}}><strong className={'white-text'}>{el.bombs}</strong><MDBIcon className={'color-text-md'} icon={'bomb'}/> </Box>
            <Box sx={{ justifySelf: 'center'}}><Balance value={el.potentialWin}/></Box>
          </Box>
        ))
      }
    </Box>
  );
};

export default BombHistoryList;