import React from 'react';
import logo from './logo2.svg';
import {ModalAuth} from "../components/ModalAuth";
import {MDBIcon} from "mdbreact";

export const LoginPage = () => {
    return (
        <div className="container-fluid h-100 w-100 authPage" style={{zIndex: "99999", position: "fixed", backgroundColor: "#0E1015"}}>
            <div className="row h-100">
                <div className="col-lg-12 m-4">
                    <img style={{width: "200px"}} className="logo" src={logo} alt=""/>
                </div>
                <div className="col-lg-5 ml-5">
                    <div className="d-flex flex-column w-100">
                        <strong className="textMain">Текущий домен сайта</strong><br/>
                        <h3 className="color__green"><strong>{window.location.hostname}</strong></h3>
                        <div className="d-flex align-items-center mt-5">
                            <div style={{width: "55px"}} className="connected d-flex justify-content-center rounded-circle p-3 mr-4"><MDBIcon className="d-block" style={{fontSize: "25px"}} icon="check-circle" /></div>
                            <div className="d-flex flex-column justify-content-center">
                                <p className="color-text-lg m-0">Теоретический процент возврата (RTP)</p>
                                <h4 className="textMain"><strong>от 96,88% во всех играх</strong></h4>
                            </div>
                        </div>
                        <div className="mt-5">
                            <ModalAuth />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}