import React from 'react';
import {Success} from './success'
import {AnimatePresence, motion} from "framer-motion";

export const Loader = (props) => {
    const [success, setSuccess] = React.useState(false)
    React.useEffect(()=> {
        setSuccess(props.success)
    },[props.success])
    return (
        <div id="fk_loader" hidden={props.hidden}>
            <AnimatePresence exitBeforeEnter={true}>
                {!success ?
                    <motion.div
                        key="1"
                        initial={{scale: 0}}
                        animate={{scale: 1}}
                        exit={{scale: 0}}
                        transition={{ duration: 0.5 }}
                    >
                        <svg id="cube"
                             xmlns="http://www.w3.org/2000/svg"
                             width="78"
                             height="78"
                             fill="none"
                             viewBox="0 0 78 78"
                        >
                            <g filter="url(#filter1_d)">
                                <path
                                    fill="#00CD39"
                                    d="M25.079 54.508L44.049 61l16.59-16.59-19.114-6.348-16.446 16.446zM61 42.102l-6.275-18.827L35.898 17l6.276 18.826L61 42.102zm-44-8.223l6.492 18.97L40.01 36.403 33.59 17.29"
                                ></path>
                            </g>
                            <path
                                fill="#053312"
                                d="M30.02 54.431c1.01.505 2.669.072 3.679-.937 1.01-1.01 1.01-2.164 0-2.67-1.01-.504-2.67-.071-3.679.938-1.01 1.01-1.01 2.164 0 2.67zM36.151 56.523c1.01.505 2.67.072 3.679-.938 1.01-1.01 1.01-2.164 0-2.669-1.01-.504-2.669-.072-3.679.938-1.01 1.01-1.01 2.164 0 2.67zM45.889 57.605c1.01-1.01 1.01-2.236 0-2.669-1.01-.433-2.669-.072-3.679.938-1.01 1.01-1.01 2.164 0 2.669 1.01.505 2.67.072 3.679-.938zM39.902 44.55c1.01.504 2.669.071 3.679-.938 1.01-1.01 1.01-2.164 0-2.67-1.01-.504-2.67-.071-3.68.938-1.009 1.01-1.009 2.164 0 2.67zM46.033 46.641c1.01.505 2.669.072 3.679-.938 1.01-1.01 1.01-2.164 0-2.668-1.01-.505-2.67-.073-3.679.937-1.01 1.01-1.01 2.164 0 2.67zM55.77 47.723c1.01-1.01 1.01-2.236 0-2.669-1.01-.433-2.668-.072-3.678.938-1.01 1.01-1.01 2.164 0 2.669 1.01.505 2.669.072 3.679-.938z"
                            ></path>
                            <path
                                fill="#053312"
                                d="M30.912 28.331l-1.218-2.713-7.685 7.683 3.936 11.02 8.407-7.7-1.146-2.715-4.875 4.442-.686-1.571 4.238-3.923-1.07-2.572-4.238 3.922-.537-1.358 4.874-4.515z"
                            ></path>
                            <path
                                fill="#053312"
                                d="M52.654 27.879l-.756-2.464-9.553-2.742.826 2.61 5.028 1.446-3.378 3.917.756 2.464 9.553 2.742-.826-2.61-5.1-1.448 3.45-3.915z"
                            ></path>
                            <defs>
                                <filter
                                    id="filter0_d"
                                    width="76"
                                    height="76"
                                    x="1"
                                    y="1"
                                    colorInterpolationFilters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                >
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    ></feColorMatrix>
                                    <feOffset></feOffset>
                                    <feGaussianBlur stdDeviation="8"></feGaussianBlur>
                                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.803922 0 0 0 0 0.223529 0 0 0 0.1 0"></feColorMatrix>
                                    <feBlend
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow"
                                    ></feBlend>
                                    <feBlend
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow"
                                        result="shape"
                                    ></feBlend>
                                </filter>
                                <filter
                                    id="filter1_d"
                                    width="77"
                                    height="77"
                                    x="0.5"
                                    y="0.5"
                                    colorInterpolationFilters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                >
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    ></feColorMatrix>
                                    <feOffset></feOffset>
                                    <feGaussianBlur stdDeviation="8.25"></feGaussianBlur>
                                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.803922 0 0 0 0 0.223529 0 0 0 0.25 0"></feColorMatrix>
                                    <feBlend
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow"
                                    ></feBlend>
                                    <feBlend
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow"
                                        result="shape"
                                    ></feBlend>
                                </filter>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="39"
                                    x2="39"
                                    y1="17"
                                    y2="61"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#03EE44"></stop>
                                    <stop offset="1" stopColor="#0EB300"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                    </motion.div>
                    :
                    <Success/>
                }
            </AnimatePresence>
        </div>
    )
}