import { SnackbarContent } from "notistack";
import winPic from "./win.png";
import success from "./success.png";
import error from "./error.png";
import React from "react";

export const Win = React.forwardRef(({ title, message ,...other }, ref) => {

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <div className={"toastBody toastSuccess"}>
        <img className="toastImg" src={winPic} alt=""/>
        <div className="d-flex flex-column flex-wrap">
          <strong className="textMain">{title}</strong>
          <p className="color-text-lg d-flex flex-row">{message}</p>
        </div>
      </div>
    </SnackbarContent>
  )
})
export const Success = React.forwardRef(({ title, message ,...other }, ref) => {

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <div className="toastBody toastSuccess" >
        <img className="toastImg" src={success} alt=""/>
        <div className="d-flex flex-column flex-wrap">
          <strong className="textMain">{title}</strong>
          <p className="color-text-lg d-flex flex-row">{message}</p>
        </div>
      </div>
    </SnackbarContent>
  )
})
export const Error = React.forwardRef(({ title, message ,...other }, ref) => {
  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <div className={"toastBody toastError"} >
       <img className="toastImg" src={error} alt=""/>
        <div className="d-flex flex-column flex-wrap">
          <strong className="textMain">{title}</strong>
          <p className="color-text-lg d-flex flex-row">{message}</p>
        </div>
      </div>
    </SnackbarContent>
  )
})

