import React, { useEffect, useState } from "react";
import {Box, Typography, useMediaQuery} from "@mui/material";
import useLang from "../hooks/lang.hook";
import { Balance } from "../components/Balance";
import { MDBIcon } from "mdbreact";
import useAuth from "../hooks/AuthHook";
import {activatePromo, checkSub, checkTgSubscribe, checkVkSubscribe, connectTg, createPromo, getAllPromo} from "../api";
import useVisual from "../hooks/VisualHook";
import {Button} from "../components/Button";
import {enqueueSnackbar} from "notistack";

const PromoPage = () => {
  const { user, setUser } = useAuth();
  const { strings } = useLang();
  const { visual } = useVisual();
  const [tgLink, setTgLink] = useState('')
  const [promo, setPromo] = useState("");
  const regex = /^[A-Z0-9]+$/;

  const [createPromoInputs, setCreatePromoInputs] = useState({
    count: "",
    code: "",
    bonus: "",
  });
  const [promoHistory, setPromoHistory] = useState([]);
  const matches = useMediaQuery("(min-width:1500px)", { noSsr: true });

  const activatePromoAction = async () => {
    const activate = await activatePromo({ code: promo });
    enqueueSnackbar(strings.responseCodes[activate.code], {
      title: activate.success ? strings.responseCodes.success : strings.responseCodes.error,
      variant: activate.success ? 'successAlert' : 'errorAlert' ,
    })
    if(activate.success) {
      setUser(prev => ({...user, balance: prev.balance + activate.sum}))
    }
  };
  const checkSubAction = async () => {
    const check = await checkSub();
    if(check.success) {
      return setUser((prev) => ({...prev, vkSub: true, telegramSub: true, telegram: true}));
    }
    enqueueSnackbar(strings.responseCodes[check.code], {
      title: strings.responseCodes.error,
      variant: 'errorAlert',
    })
  }
  const createPromoAction = async () => {
    const createdPromo = await createPromo(createPromoInputs);
    enqueueSnackbar(strings.responseCodes[createdPromo.code], {
      title: createdPromo.success ? strings.responseCodes.success : strings.responseCodes.error,
      variant: createdPromo.success ? 'successAlert' : 'errorAlert' ,
    })
    if (createdPromo.success) {
      setPromoHistory((prev) => [createdPromo.promo, ...prev]);
    }
  };

  useEffect(() => {
    getAllPromo().then((val) => {
      if (val.success) {
        setPromoHistory(val.data);
      }
    });
  }, []);
  const checkTgSubAction = async () => {
    const check = await checkTgSubscribe();
    if(check.success) {
      setUser(prev => ({...prev, bonusBalance: check.data.bonusBalance, telegramSub: check.data.telegramSub}))
    }
  }
  const checkVkSubAction = async () => {
    const check = await checkVkSubscribe();
    if(check.success) {
      setUser(prev => ({...prev, bonusBalance: check.data.bonusBalance, vkSub: check.data.vkSub}))
    }
  }
  const connectTgAction = async () => {
    const connect = await connectTg();
    if(connect.success) {
      window.open(connect.link);
      setTgLink(connect.link)
    }
  }

  return (
    <div className="container-fluid overflow-auto scrollbar-primary scrollbar mt-2 h-100">
      <div className="row">
        <div className="bonus col-md-12 d-flex flex-wrap justify-content-between">
          <Box width={"100%"} gap={"50px"} display={"grid"}>
            <Box
              gridTemplateAreas={
                matches
                  ? ((user.deps < 1000 || !user.telegramSub || !user.vkSub) ? "'check activate create create''check list list list'" : "'activate create create''list list list'" ) : "'check' 'check2' 'activate' 'create' 'list'"
              }
              gridTemplateColumns={matches && (user.deps > 1000 && user.telegramSub && user.vkSub) ? '1fr 1fr' : ''}
              display={"grid"}
              gap={"50px"}
              justifyContent={"center"}
            >

                {(!user.telegramSub || !user.vkSub) && (
                  <Box area={'check'}>
                  <Box
                    className={"mainColor color-text-lg"}
                    display={"grid"}
                    width={"100%"}
                    borderRadius={"5px"}
                    padding={'20px'}
                    gap={'16px'}
                  >
                    <Box
                    >
                      <Typography fontSize={18} className={'textMain'} component={'strong'}>
                        <strong>Для активации промокодов требуется</strong>
                      </Typography>
                    </Box>
                    <hr style={{ margin: '1px 0'}}/>
                    <Box
                      display={"grid"}
                      gap={"10px"}
                      className="levels__terms d-flex flex-column"
                    >
                      <Box
                        display={"grid"}
                        gridAutoFlow={"column"}
                        alignItems={"center"}
                        gap={'12px'}
                        gridTemplateColumns={'auto 1fr'}
                      >
                        <MDBIcon className={"color-text-lg"} fab icon="vk" />
                        <span>Подписка на <a className={'balance'} target="_blank" href={visual?.vk}>группу ВК</a></span>
                      </Box>
                      <Box
                        display={"grid"}
                        gridAutoFlow={"column"}
                        alignItems={'center'}
                        gap={'12px'}
                        gridTemplateColumns={'auto 1fr'}

                      >
                        <MDBIcon
                          className={"color-text-lg"}
                          fab
                          icon="telegram"
                        />
                        <span>Привязка <strong style={{ cursor: 'pointer'}} onClick={connectTgAction} className={'sidebar__icon-active'}>TG-аккаунта</strong></span>
                      </Box>
                      <Box
                        display={"grid"}
                        gridAutoFlow={"column"}
                        alignItems={'center'}
                        gap={'12px'}
                        gridTemplateColumns={'auto 1fr'}

                      >
                        <MDBIcon
                          className={"color-text-lg"}
                          fab
                          icon="telegram"
                        />
                        <span>Подписка на <a target="_blank" className={'balance'} href={visual?.tg}>TG-канал</a></span>
                      </Box>
                      <Box onClick={checkSubAction} display={'flex'} gap={'5px'} justifyContent={'center'}  className="p-2 pb-2 pr-4 pl-4 font-weight-bold white-grad my-text-dark button m-1">
                        <MDBIcon
                          fas
                          icon={'search'}
                        />
                        <span className="d-flex flex-center text-center">Проверить подписки</span>
                      </Box>
                    </Box>
                  </Box>
                  </Box>
                )}
                { (user.deps < 1000 || !user.telegramSub || !user.vkSub) && (
                  <Box area={'check2'}>
                  <Box
                    className={"mainColor color-text-lg"}
                    display={"grid"}
                    width={"100%"}
                    borderRadius={"5px"}
                    padding={'20px'}
                    gap={'16px'}
                  >
                    <Box
                    >
                      <Typography fontSize={18} className={'textMain'} component={'strong'}>
                        <strong>Для создания промокодов требуется</strong>
                      </Typography>
                    </Box>
                    <hr style={{ margin: '1px 0'}}/>
                    <Box
                      display={"grid"}
                      gap={"10px"}
                      className="levels__terms d-flex flex-column"
                    >
                      <Box
                        display={"grid"}
                        gridAutoFlow={"column"}
                        alignItems={"center"}
                        gap={'12px'}
                        gridTemplateColumns={'auto 1fr'}
                      >
                        <MDBIcon className={"color-text-lg"} fab icon="vk" />
                        <span>Подписка на <a target="_blank" className={'balance'} href={visual?.vk}>группу ВК</a></span>
                      </Box>
                      <Box
                        display={"grid"}
                        gridAutoFlow={"column"}
                        alignItems={'center'}
                        gap={'12px'}
                        gridTemplateColumns={'auto 1fr'}

                      >
                        <MDBIcon
                          className={"color-text-lg"}
                          fab
                          icon="telegram"
                        />
                        <span>Привязка <strong style={{ cursor: 'pointer'}} onClick={connectTgAction} className={'sidebar__icon-active'}>TG-аккаунта</strong></span>
                      </Box>
                      <Box
                        display={"grid"}
                        gridAutoFlow={"column"}
                        alignItems={'center'}
                        gap={'12px'}
                        gridTemplateColumns={'auto 1fr'}

                      >
                        <MDBIcon
                          className={"color-text-lg"}
                          fab
                          icon="telegram"
                        />
                        <span>Подписка на <a target="_blank" className={'balance'} href={visual?.tg}>TG-канал</a></span>
                      </Box>
                      <Box
                        display={"grid"}
                        gridAutoFlow={"column"}
                        alignItems={"center"}
                        gap={'12px'}
                        gridTemplateColumns={'auto 1fr'}
                      >
                        <MDBIcon
                          className={"color-text-lg"}
                          fas
                          icon="coins"
                        />
                        <Box display={'flex'} gap={'5px'}> Депозит от <Balance value={1000}/> </Box>
                      </Box>
                      <Box onClick={checkSubAction} display={'flex'} gap={'5px'} justifyContent={'center'}  className="p-2 pb-2 pr-4 pl-4 font-weight-bold white-grad my-text-dark button m-1">
                        <MDBIcon
                          fas
                          icon={'search'}
                        />
                        <span className="d-flex flex-center text-center">Проверить подписки</span>
                      </Box>
                    </Box>
                  </Box>
                  </Box>
                )}
              <Box width={(user.deps > 1000 && user.telegramSub && user.vkSub) ? '350px' : '100%'} gridArea={"activate"} display={"grid"} gap={"20px"} alignItems={'start'} gridTemplateRows={'auto 1fr'} justifySelf={'center'}>
                <h5 className={"textMain"}>
                  <strong>Активировать промокод</strong>
                </h5>
                <Box display={"grid"} alignItems={'start'} gap={"10px"}>
                  <input
                    id="payForm"
                    value={promo}
                    onChange={(e) => setPromo(regex.test(e.target.value.toUpperCase()) ? e.target.value.toUpperCase() : '')}
                    type="text"
                    placeholder="Код"
                    className="payment__input mb-3 mainPanelColor"
                  />
                  <hr style={{ margin: '1px 0'}}/>
                  <Button onClick={activatePromoAction} className={'m-0'}><MDBIcon fas icon="paper-plane" /> Активировать промокод</Button>
                </Box>
              </Box>
              <Box width={(user.deps > 1000 && user.telegramSub && user.vkSub) ? '350px' : '100%'} justifySelf={'center'} gridArea={"create"} display={"grid"} gap={"20px"}>
                <h5 className={"textMain"}>
                  <strong>Создать промокод</strong>
                </h5>
                <Box display={"grid"}>
                  <input
                    id="payForm"
                    value={createPromoInputs.code}
                    onChange={(e) =>
                      setCreatePromoInputs((prev) => ({
                        ...prev,
                        code: regex.test(e.target.value.toUpperCase()) ? e.target.value.toUpperCase() : '',
                      }))
                    }
                    type="text"
                    placeholder="Код"
                    className="payment__input mb-3 mainPanelColor"
                  />
                  <input
                    id="payForm"
                    value={createPromoInputs.count}
                    onChange={(e) =>
                      setCreatePromoInputs((prev) => ({
                        ...prev,
                        count: e.target.value,
                      }))
                    }
                    type="text"
                    placeholder="Кол-во активаций"
                    className="payment__input mb-3 mainPanelColor"
                  />
                  <input
                    id="payForm"
                    value={createPromoInputs.bonus}
                    onChange={(e) =>
                      setCreatePromoInputs((prev) => ({
                        ...prev,
                        bonus: e.target.value,
                      }))
                    }
                    type="text"
                    placeholder="Награда"
                    className="payment__input mb-3 mainPanelColor"
                  />
                  <hr style={{ margin: '5px 0'}}/>
                  <Button onClick={createPromoAction} className={'m-0'}><MDBIcon fas icon="pen" /> Создать промокод</Button>

                </Box>
              </Box>
              <Box gridArea={"list"} display={"grid"} gap={"20px"}>
                <h5 className={"textMain"}>
                  <strong>Созданные промокоды</strong>
                </h5>
                <Box
                  overflow={"auto"}
                  gap={"50px"}
                  position={"relative"}
                  justifyContent={"start"}
                >
                  <Box
                    display={"grid"}
                    gap={"12px"}
                    gridTemplateRows={"min-content"}
                  >
                    <Box
                      justifyItems={"center"}
                      display={"grid"}
                      alignItems={"start"}
                      gridTemplateColumns={"1fr 1fr 1fr"}
                      gridAutoFlow={"column"}
                      gap={"6px"}
                    >
                      <strong className={"color-text-lg"}>Код</strong>
                      <strong className={"color-text-lg"}>Награда</strong>
                      <strong className={"color-text-lg"}>
                        Активаций осталось
                      </strong>
                    </Box>
                    {promoHistory.length > 0 ? (
                      promoHistory.map((el) => (
                        <Box
                          justifyItems={"center"}
                          display={"grid"}
                          alignItems={"start"}
                          gridTemplateColumns={"1fr 1fr 1fr"}
                          gridAutoFlow={"column"}
                          gap={"6px"}
                          className="mainColor"
                          sx={{
                            borderRadius: '5px',
                            border: '1px solid',
                            borderColor: 'rgba(198, 204, 221, 0.05)',
                            padding: '10px',
                          }}
                        >
                          <strong className={"white-text"}>{el.code}</strong>
                          <Balance value={el.bonus} />
                          <strong className={"textMain"}>
                            {el.count}
                          </strong>
                        </Box>
                      ))
                    ) : (
                      <Box textAlign={"center"} className={"color-text-md"}>
                        <span>Нет промокодов.</span>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default PromoPage;
