import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getVisual } from "../api";

const visualContext = createContext()

export function ProvideVisual({ children }) {
  const visual = useVisualProvide();
  return <visualContext.Provider value={visual}>{children}</visualContext.Provider>;
}

export const useVisual = () => {
  return useContext(visualContext);
};

const useVisualProvide = () => {
  const [isReady, setIsReady] = useState(false);
  const [visual, setVisual] = useState({})

  const getVisualAction = useCallback(async () => {
    const visualData = await getVisual()
    if (visualData.success) {
      return setVisual(visualData.visual[0]);
    }
    return false;
  }, []);

  useEffect(() => {
    getVisualAction().then(() => {
      setIsReady(true)
    }).catch(() => {
      setIsReady(true)
    });
  }, [getVisualAction]);

  return {
    visual, isReady
  };

};

export default useVisual;
