import React, { useEffect, useState } from "react";
import { MDBAnimation, MDBIcon } from "mdbreact";
import { Balance } from "../components/Balance";
import { NavLink } from "react-router-dom";
import { CDataTable } from "@coreui/react";
import useLang from "../hooks/lang.hook";
import { getTop } from "../api";

export const TopPage = () => {
  const [top, setTop] = useState([]);
  const { strings, } = useLang()
  
  useEffect(() => {
    getTop().then((val) => {
      setTop(val);
    })
  }, []);

  return (
    <div className="top-page overflow-auto scrollbar-primary scrollbar d-flex flex-row flex-wrap mainColor mt-2">
      <NavLink
        className="color-text-lg backroom d-flex align-items-center m-3"
        to={"/"}
      >
        <MDBIcon icon="long-arrow-alt-left" />{" "}
        <span>{strings.back}</span>
      </NavLink>
      {top.length >= 3 && (
        <div className="places-top align-self-center justify-content-center w-100 flex-row flex-wrap">
          <MDBAnimation
            type="flipInX"
            className="places-top__user d-flex flex-column order-1 align-items-center"
            delay="1.3s"
          >
            <div className="user-top__avatar ">
              <svg
                className="user-top__place "
                width="154"
                height="169"
                viewBox="0 0 137 137"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8467 22.8467C18.8467 20.6376 20.6376 18.8467 22.8467 18.8467H113.847C116.056 18.8467 117.847 20.6376 117.847 22.8467V113.847C117.847 116.056 116.056 117.847 113.847 117.847H22.8467C20.6376 117.847 18.8467 116.056 18.8467 113.847V22.8467Z"
                  fill="#00CD39"
                />
                <path
                  d="M65.5183 1.17157C67.0804 -0.390525 69.613 -0.390524 71.1751 1.17157L135.522 65.5183C137.084 67.0804 137.084 69.613 135.522 71.1751L71.1751 135.522C69.613 137.084 67.0804 137.084 65.5183 135.522L1.17157 71.1751C-0.390525 69.613 -0.390524 67.0804 1.17157 65.5183L65.5183 1.17157Z"
                  fill="#00CD39"
                />
                <path
                  d="M125.847 68.8467C125.847 100.879 99.8792 126.847 67.8467 126.847C35.8142 126.847 9.84672 100.879 9.84672 68.8467C9.84672 36.8142 35.8142 10.8467 67.8467 10.8467C99.8792 10.8467 125.847 36.8142 125.847 68.8467Z"
                  fill="#00CD39"
                />
              </svg>
              <img
                className="user-top__avatar-img border__one"
                src={top[0].avatar}
                alt=""
              />
              <span className="user-top__num one">1</span>
            </div>
            <span
              className="textMain font-weight-bolder"
              style={{ fontSize: "14px" }}
            >
                  {top[0].username}
                </span>
            <span className="textMain font-weight-bold">
                  <MDBIcon className="color__green" icon="crown" />
              {top[0].wins}
                </span>
            <Balance className="top__balance" value={top[0].bank} />
          </MDBAnimation>
          <MDBAnimation
            type="flipInX"
            className="places-top__user d-flex flex-column m-5 align-items-center "
            delay="0.9s"
          >
            <div className="user-top__avatar ">
              <svg
                className="user-top__place"
                width="154"
                height="169"
                viewBox="0 0 137 137"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="two"
                  d="M18.8467 22.8467C18.8467 20.6376 20.6376 18.8467 22.8467 18.8467H113.847C116.056 18.8467 117.847 20.6376 117.847 22.8467V113.847C117.847 116.056 116.056 117.847 113.847 117.847H22.8467C20.6376 117.847 18.8467 116.056 18.8467 113.847V22.8467Z"
                  fill="#00CD39"
                />
                <path
                  className="two"
                  d="M65.5183 1.17157C67.0804 -0.390525 69.613 -0.390524 71.1751 1.17157L135.522 65.5183C137.084 67.0804 137.084 69.613 135.522 71.1751L71.1751 135.522C69.613 137.084 67.0804 137.084 65.5183 135.522L1.17157 71.1751C-0.390525 69.613 -0.390524 67.0804 1.17157 65.5183L65.5183 1.17157Z"
                  fill="#00CD39"
                />
                <path
                  className="two"
                  d="M125.847 68.8467C125.847 100.879 99.8792 126.847 67.8467 126.847C35.8142 126.847 9.84672 100.879 9.84672 68.8467C9.84672 36.8142 35.8142 10.8467 67.8467 10.8467C99.8792 10.8467 125.847 36.8142 125.847 68.8467Z"
                  fill="#00CD39"
                />
              </svg>
              <img
                className="user-top__avatar-img border__two"
                src={top[1].avatar}
                alt=""
              />
              <span className="user-top__num two">2</span>
            </div>
            <span
              className="textMain font-weight-bolder"
              style={{ fontSize: "14px" }}
            >
                  {top[1].username}
                </span>
            <span className="textMain font-weight-bold">
                  <MDBIcon className="color__green" icon="crown" />
              {top[1].wins}
                </span>
            <Balance className="top__balance" value={top[1].bank} />
          </MDBAnimation>
          <MDBAnimation
            type="flipInX"
            className="places-top__user d-flex flex-column align-items-center m-5 order-2"
            delay="0.3s"
          >
            <div className="user-top__avatar">
              <svg
                className="user-top__place"
                width="154"
                height="169"
                viewBox="0 0 137 137"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="three"
                  d="M18.8467 22.8467C18.8467 20.6376 20.6376 18.8467 22.8467 18.8467H113.847C116.056 18.8467 117.847 20.6376 117.847 22.8467V113.847C117.847 116.056 116.056 117.847 113.847 117.847H22.8467C20.6376 117.847 18.8467 116.056 18.8467 113.847V22.8467Z"
                  fill="#00CD39"
                />
                <path
                  className="three"
                  d="M65.5183 1.17157C67.0804 -0.390525 69.613 -0.390524 71.1751 1.17157L135.522 65.5183C137.084 67.0804 137.084 69.613 135.522 71.1751L71.1751 135.522C69.613 137.084 67.0804 137.084 65.5183 135.522L1.17157 71.1751C-0.390525 69.613 -0.390524 67.0804 1.17157 65.5183L65.5183 1.17157Z"
                  fill="#00CD39"
                />
                <path
                  className="three"
                  d="M125.847 68.8467C125.847 100.879 99.8792 126.847 67.8467 126.847C35.8142 126.847 9.84672 100.879 9.84672 68.8467C9.84672 36.8142 35.8142 10.8467 67.8467 10.8467C99.8792 10.8467 125.847 36.8142 125.847 68.8467Z"
                  fill="#00CD39"
                />
              </svg>
              <img
                className="user-top__avatar-img border__three"
                src={top[2].avatar}
                alt=""
              />
              <span className="user-top__num three">3</span>
            </div>
            <span
              className="textMain font-weight-bolder"
              style={{ fontSize: "14px" }}
            >
                  {top[2].username}
                </span>
            <span className="textMain font-weight-bold">
                  <MDBIcon className="color__green" icon="crown" />
              {top[2].wins}
                </span>
            <Balance className="top__balance" value={top[2].bank} />
          </MDBAnimation>
        </div>
      )}
      <div className="w-100">
        <CDataTable
          addTableClasses="datatable__top"
          items={top}
          responsive
          fields={[
            { key: "place", label: "№" },
            { key: "username", label: strings.top.name },
            { key: "wins", label: strings.top.wins },
            { key: "bank", label: strings.top.sum },
          ]}
          noItemsViewSlot={"No players"}
          itemsPerPage={10}
          border={false}
          outlined={false}
          hover
          sorter
          pagination={{
            doubleArrows: false,
            align: "center",
            dots: false,
            limit: 3,
            previousButton: <MDBIcon icon="angle-left" />,
            nextButton: <MDBIcon icon="angle-right" />,
          }}
          scopedSlots={{
            username: (user) => (
              <td>
                <div className="d-flex align-items-center">
                  <img
                    style={{ width: "36px" }}
                    className="avatar p-0 m-0 mr-2"
                    src={user.avatar}
                    alt=""
                  />
                  <span className="textMain">{user.username}</span>
                </div>
              </td>
            ),
            wins: (game) => (
              <td className="textMain font-weight-bold">
                <MDBIcon className="color__green" icon="crown" />{" "}
                {game.wins}
              </td>
            ),
            place: (game, index) => (
              <td className={`place_${index + 1} textMain`}>{index + 1}</td>
            ),
            bank: (user) => (
              <td>
                <Balance value={user.bank} />
              </td>
            ),
          }}
        />
      </div>
    </div>
  );
};
