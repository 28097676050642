import React, { useEffect, useState } from "react";
import { MDBIcon } from "mdbreact";
import useLang from "../hooks/lang.hook";
import { Balance } from "../components/Balance";
import {activateBonus, checkReposts, checkSub, connectTg, getBonus, getLevels, getReward} from "../api";
import useAuth from "../hooks/AuthHook";
import Moment from "react-moment";
import Box from "@mui/material/Box";
import useVisual from "../hooks/VisualHook";
import {Typography} from "@mui/material";
import {enqueueSnackbar} from "notistack";

export const BonusPage = ({ toggleSupport }) => {
  const { currentLang, strings } = useLang();
  const { visual } = useVisual()
  const { user, setUser } = useAuth();
  const [tgLink, setTgLink] = useState('')
  const [levels, setLevels] = useState([]);
  const [reposts, setReposts] = useState(0)
  const [bonusList, setBonusList] = useState([]);
  const activateBonusAction = async (bonus) => {
    const bonusActivate = await activateBonus(bonus);
    enqueueSnackbar(strings.responseCodes[bonusActivate.code], {
      title: bonusActivate.success ? strings.responseCodes.success : strings.responseCodes.error,
      variant: bonusActivate.success ? 'successAlert' : 'errorAlert' ,
    })
    if(bonusActivate.success) {
      setUser(prev => ({...prev, bonusBalance: bonusActivate.data.updateBalance.bonusBalance }))
      console.log(bonusList)
      console.log(bonusActivate.data.bonus.date)
      setBonusList(bonusActivate.data.updatedBonus)
    }
  }
  const checkSubAction = async () => {
    const check = await checkSub();
    if(check.success) {
      return setUser((prev) => ({...prev, vkSub: true, telegramSub: true}));
    }
  }
  const getBonusAction = () => {
    getReward().then( val => {
      if(val.success) {
        setUser(prev => ({...prev, bonusBalance: val.data.bonusBalance, balance: val.data.balance }))
      }
    })
  }
  const connectTgAction = async () => {
    const connect = await connectTg();
    if(connect.success) {
      window.open(connect.link);
      setTgLink(connect.link)
    }
  }
  const checkRepostsAction = () => {
    checkReposts().then(val => {
      if(val.success) {
        setReposts(val.data.reposts)
        setUser(prev => ({...prev, bonusBalance: val.data.bonusBalance }))
      }
      enqueueSnackbar(strings.responseCodes[val.code], {
        title: val.success ? strings.responseCodes.success : strings.responseCodes.error,
        variant: val.success ? 'successAlert' : 'errorAlert' ,
      })
    })
  }
  useEffect(() => {
    getLevels().then(val => {
      if(val.success) {
        setLevels(val.data.levels);
        setReposts(val.data.userReposts)
      }
    })
    getBonus().then(val => {
      if(val.success) {
        setBonusList(val.data);
      }
    })
  }, [])
  return (
    <div className="container-fluid overflow-auto scrollbar-primary scrollbar mt-2 h-100">
      <div className="row">
        <Box className="bonus col-md-12 flex-wrap justify-content-between">
          <Box gap={'20px'} alignItems={'center'}  display={'grid'} gridTemplateColumns={'repeat(auto-fit, minmax(300px, 1fr))'}>
            <Box borderRadius={'5px'} height={'100%'} padding={'10px 20px'} justifyContent={'center'} display={'flex'} gap={'20px'} flexDirection={'column'} className={'room__active'}>
              <Box display={'flex'} gap={'10px'}>
                <strong className="textMain">Ваш бонусный баланс:</strong>
                <Balance value={user.bonusBalance}/>
              </Box>
              <Box display={'flex'} gap={'10px'}>
                <Box display={'flex'} alignItems={'center'} gap={'5px'} onClick={checkRepostsAction} className="p-2 pb-2 pr-4 pl-4 font-weight-bold white-grad my-text-dark button m-1"><MDBIcon fas icon="search" />
                  Проверить посты</Box>
                <Box
                  display={'flex'} alignItems={'center'} gap={'5px'}
                  onClick={user.bonusBalance > 0 && (() => getBonusAction())}
                  className={`${user.bonusBalance <= 0 && 'button-dis'} p-2 pb-2 pr-4 pl-4 font-weight-bold white-grad my-text-dark button m-1`}
                >
                  <MDBIcon fas icon="coins" />
                   Получить награду
                </Box>
              </Box>
            </Box>
            <Box borderRadius={'5px'} padding={'10px'} className={'mainPanelColor'} display={'flex'} flexDirection={'column'} fontSize={'16px'}>
              <span className="color-text-lg"><MDBIcon icon="check-circle" className="color__green" style={{margin: "8px"}}/>Делай репосты и получай бонус</span>
              <span className="color-text-lg"><MDBIcon icon="check-circle" className="color__green" style={{margin: "8px"}}/>Чем больше репостов, тем больше бонус</span>
              <span className="color-text-lg"><MDBIcon icon="check-circle" className="color__green" style={{margin: "8px"}}/>Учитываются только 10 последних постов</span>
              <span className="color-text-lg"><MDBIcon icon="check-circle" className="color__green" style={{margin: "8px"}}/>Профиль ВК должен быть открыт</span>
            </Box>
          </Box>
          <hr style={{ marginTop: '20px', height: '1px'}} className={'room__active'}/>
          <Box display={'grid'} gridTemplateColumns={'repeat(auto-fit, minmax(300px, 1fr))'} gap={'20px'}>
            <Box className="dailys">
              <Box className="levels__items d-flex justify-content-start flex-wrap flex-column" gap={'20px'}>
                {
                  bonusList.map(el => (
                    <Box className="levels__item flex-column">
                      <Box fontSize={'18px'} padding={'16px 20px'}  display={'flex'} gap={'10px'}>
                        <strong className="textMain">{el.once ? `Единоразовый бонус` : `Бонус (${el.period}ч.)`}</strong>
                      </Box>
                      <hr style={{ margin: '1px 20px', height: '1px'}} className={'room__active'}/>
                      <Box margin={'16px 20px'}className="levels__terms d-flex flex-wrap" gap={'20px'} fontSize={'16px'}>
                        <Typography display={'flex'} gap={'10px'} alignItems={'center'} component={'span'} className="text-white"><MDBIcon className={'balance'} fab icon="vk"/><span>Подписка на <a target="_blank" className={'balance'} href={visual?.vk}>группу ВК</a></span></Typography>
                        <Typography display={'flex'} gap={'10px'} alignItems={'center'} component={'span'} className="text-white"><MDBIcon className={'balance'} fab icon="telegram"/><span>Привязка <strong style={{ cursor: 'pointer'}} onClick={connectTgAction} className={'sidebar__icon-active'}>TG-аккаунта</strong></span></Typography>
                        <Typography display={'flex'} gap={'10px'} alignItems={'center'} component={'span'} className="text-white"><MDBIcon className={'balance'} fab icon="telegram"/><span>Подписка на <a target="_blank" className={'balance'} href={visual?.tg}>TG-канал</a></span></Typography>
                      </Box>
                      <Box mb={'16px'} mx={'20px'} className="levels__item-footer">
                        <div onClick={async () => await activateBonusAction(el._id)} className="p-2 pb-2 pr-4 pl-4 font-weight-bold white-grad my-text-dark button m-1">
                          <span className="d-flex">
                            {el?.activations && el.once ? (
                                <>Бонус активирован</>
                              ) :
                              el?.activations && new Date().getTime() <= new Date(el?.activations.updatedAt).getTime() + el.period*60*60*1000 ? (
                                  <>
                                    <strong>Ожидание</strong>
                                    <Moment interval={1000} trim date= {new Date(el?.activations.updatedAt).getTime() + el.period*60*60*1000} format="hh:mm:ss" durationFromNow />
                                  </>
                                ) :
                                <>Получить до <Balance className="ml-1 text-black-50" value={el.value}/></>
                            }
                          </span>
                        </div>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
            <Box>
              <Box className="levels__items" gap={'20px'}>
                {
                  levels.length && levels.map(el => (
                    <Box className="levels__item flex-column">
                      <Box fontSize={'18px'} padding={'16px 20px'} display={'flex'} gap={'10px'} justifyContent={'space-between'}>
                         <strong className={'textMain'}>Уровень {el.level}
                          <span className="levels__item-desc color-text-lg m-1">
                            <strong>– {el.repostsLength} репостов</strong>
                          </span>
                        </strong>
                        <div className="levels__item-head text-white">

                          <Balance value={el.reward} />
                        </div>
                      </Box>
                      <hr style={{ margin: '1px 20px', height: '1px'}} className={'room__active'}/>
                      <Box padding={'20px'} className="levels__item-body">
                        <Box fontSize={'16px'} className="levels__item-stat ">
                          <strong><span className="color__green">{reposts} из {el.repostsLength}</span></strong>
                        </Box>
                        <Box mt={'10px'} className="levels__item-progress">
                          <div className="connected" style={{ width: `${reposts > el.repostsLength ? 100 : reposts * 100 / el.repostsLength}%`, height: '100%', borderRadius: '3px'}}>
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};
