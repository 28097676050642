import React, {useEffect, useState} from 'react';
import {BetUser} from "../BetUser";
import {CCollapse} from "@coreui/react";
import { Axios } from "../../services/axios";

export const GameBets = ({domain, gameId, details, index}) => {
    const [bets, setBets] = useState([])
    useEffect(()=> {
        const getGameBets = async () => {
            const query = await Axios.get(`/api/game/getGameBets`, {params: {id: gameId}})
            setBets(query.data.bets)
        }
        getGameBets()
    } , [])
   return (
       <CCollapse className="" show={details.includes(index)}>
           <div className="d-flex row">{bets.map(bet =>
               <div className="col-lg-3 col-sm-3">
                   <BetUser user={bet}/>
               </div>
           )}</div>
       </CCollapse>
   )
}