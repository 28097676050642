import React, { useState } from 'react';
import keySvg from './key.svg'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import { Authvk } from '../Authvk';
import useAuth from "../../hooks/AuthHook";
import {useMediaQuery} from "@mui/material";


export const ModalAuth = () => {
  const { login } = useAuth();
  const matches = useMediaQuery('(min-width:800px)');
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal)
  };
  return (
    <MDBContainer>
      {/* BUTTON */}
      <button onClick={toggle} className="btn font-weight-bold button d-flex align-items-center">
        <MDBIcon icon="coins" style={{ fontSize: "20px", paddingRight: "10px" }}/>
        <strong className="fw-100 text-dark">Начать игру</strong></button>
      {/* MODAL */}
      <MDBModal overflowScroll={!matches} contentClassName="mainColor" isOpen={modal} toggle={toggle} centered size="sm">
        <MDBModalHeader className="border-0" toggle={toggle}><img className="mr-2" src={keySvg} alt=""/> <strong
          className="textMain titleMain">Вход</strong></MDBModalHeader>
        <MDBModalBody className="text-center">
          <span className="color-text-lg">Войдите через соц. сети</span>
        </MDBModalBody>
        <MDBModalFooter className="border-0 d-flex flex-column flex-center flex-1">
          {/* eslint-disable-next-line no-undef */}
          <Authvk loginHandlerVk={login} apiId={APP_ID} redirectUri={`${BASE_URL}/api/auth/vk/callback`}
                  className="vkColor w-100 d-block m-0 mt-2 btn">
            <MDBIcon fab icon="vk" className="mr-2" size="lg"/> VKONTAKTE
          </Authvk>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
}
export default ModalAuth;